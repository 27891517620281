import React from "react";
import { withRouter, NavLink} from 'react-router-dom';
import logo from "../images/logo.png";
import logo_text from "../images/ads_logo.png";

const SideNavigationBarHeader = props => {

    if (props.drawerExpanded) {
        return (
            <div className="nav-header">
                <NavLink className="brand-title" to="/segmentation-dashboard">
                    <img className="brand-title" src={logo_text} alt=''/>
                </NavLink>
            </div>
        )
    }
    else {
        return (
            <div className="nav-header" style={{width:"65px"}}>
                <div style={{marginTop:"2rem", marginRight:"25px", marginLeft:"0px"}}>
                <NavLink className="brand-logo" to="/segmentation-dashboard">
                    <img className="logo-abbr" src={logo} width={"50px"} alt=''/>
                </NavLink>
            </div>
            </div>
        )
    }

}

export default withRouter(SideNavigationBarHeader);