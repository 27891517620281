import React, {useEffect, useState} from "react";
import { Button } from "@progress/kendo-react-buttons";
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {Input, RadioButton, TextArea} from "@progress/kendo-react-inputs";
import {loadAccessibleGroups} from "../../../actions/UserActions";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../../Utils/Common";
import {MERGE_SEGMENT, SUCCESS_STATUS} from "../../../api/constants";
import LoadOverlay from "../SharedComponents/LoadOverlay";
import "./MergeSegments.css";
import {Label} from "@progress/kendo-react-labels";
import {loadSegmentDetails} from "../../../actions/TreeDashboardActions";

const TreeMergeSegments = (props) => {
    let merge_segments = props.mergeSegments.profileFormik.values.selectedSegments; //clone
    const localSegmentType = merge_segments.length > 0 ? merge_segments[0].subType.toUpperCase() : null
    const [segment_type, setSegment_type] = useState(localSegmentType);
    const [visible, setVisible] = useState(props.visible);
    // merge_segments = merge_segments.filter((segment) => segment.subType === localSegmentType) // filter only one type segment

    const [accessibleGroups, setAccessibleGroups] = useState([]);
    const [pageLoader, setPageLoader] = useState(false);
    const [mergeType, setMergeType] = useState("DISTINCT");
    const [targetType, setTargetType] = useState("CONSUMER");
    const [segmentName, setSegmentName] = useState("");
    const [groupValue, setGroupValue] = useState({});
    const [notes, setNotes] = useState("");
    const regex = RegExp(/^[a-zA-Z0-9_\- ]{3,64}$/);
    const REQUIRED_MESSAGE = "Name must be 3-64 chars containing letters, numbers, hyphens, spaces and underscores."
    const NOTES_OPTIONAL_MESSAGE = "(Optional) Use Notes to write a brief description about this segment.";
    const [isValid, setIsValid] = useState(true);
    const [disableMerge, setDisableMerge] = useState(true);
    const [encryptedSegmentIds, setEncryptedSegmentIds] = useState([]);

    useEffect(() => {
        setPageLoader(true)
        loadAccessibleGroups().then((groups) => {
            try {
                if (segment_type === "CONSUMER" || segment_type === "CONSUMER-VISITOR" || segment_type === "CONSUMER-REMAINING") {
                    setTargetType("CONSUMER");
                } else if (segment_type === "BUSINESS" || segment_type === "BUSINESS-VISITOR" || segment_type === "BUSINESS-REMAINING") {
                    setTargetType("BUSINESS");
                }

                // setTargetType(segment_type);
                setAccessibleGroups(groups)
                setGroupValue(groups[0]);
            } catch (e) {
            } finally {
                setPageLoader(false)
            }
        })
        props.mergeSegments.profileFormik.values.selectedSegments.map((i)=>loadSegmentDetails(i.id).then((r)=>setEncryptedSegmentIds(prevState => [...prevState, r.segment_id])))
        // loadSegmentDetails()
    }, [])

    useEffect(() => {
        setSegment_type(localSegmentType)
    }, [merge_segments])

    const mergeSegments = async () => {

        setPageLoader(true)
        try {
            const payload = {
                segment_name: segmentName,
                group_id: groupValue.id
            }
            if (notes) {
                payload.notes = notes
            }
            payload.segment_design = {
                merge_type: mergeType,
                target_type: targetType,
                merge_segment_id: encryptedSegmentIds
            }
            let response = await semcastAxios.post(
                MERGE_SEGMENT,
                payload,
                {
                    withCredentials: true,
                }
            );
            if (response.data.status === SUCCESS_STATUS) {
                showSuccessToastMessage("Segments Merge Request Sent Successfully.");
                props.reloadAfterMerge();
            }
            ;
        } catch (error) {
            showErrorToastMessage("Segments Merged Fail.");
        } finally {
            setPageLoader(false);
            setVisible(false);
        }
    };

    const segments = merge_segments?.length > 0
        && merge_segments.map((item, i) => {
            return (
                <li style={{paddingBottom: ".2rem", fontSize: "14px"}} key={i}
                    value={item.id}>{item.objectName}</li>
            )
        }, this);

    const handleMergeTypeChange = (event) => {
        setMergeType(event.value);
    };

    const handleTargetTypeChange = (event) => {
        setTargetType(event.value);
    };

    const handleSegmentNameChange = (event) => {
        const value = event.value;
        if (!regex.test(value)) {
            setIsValid(false);
            setDisableMerge(true);
        } else {
            setIsValid(true);
            setDisableMerge(false);
        }
        setSegmentName(value);
    };

    const handleGroupChange = (event) => {
        setGroupValue(event.target.value);
    };

    const handleNotesChange = (event) => {
        setNotes(event.target.value);
    };

    return (
        <>
            <div id="mergeSegments">
                {visible && (<Dialog
                    title={"Merge Segments"}
                    width={720}
                    closeIcon={false}
                    className={"dialog-standard"}
                >
                    <LoadOverlay active={pageLoader}>
                        {/*<div style={{paddingTop: "1.2rem"}}>*/}
                        <div>

                            <Label
                                className={"field-label left-of-field-label"}
                            >
                                Merge Type
                                {/*<span className="required-field">*</span>*/}
                            </Label>

                            {/*<label htmlFor="merge_type" className="k-radio-label"*/}
                            {/*       style={{marginLeft: "2rem", paddingRight: "1.8rem", fontSize: "1.1rem"}}>*/}
                            {/*    <b>Merge Type</b>*/}
                            {/*    <span class="text-danger">*</span>*/}
                            {/*</label>*/}
                            <br/>
                            <RadioButton
                                name="mergeType"
                                value="DISTINCT"
                                onChange={handleMergeTypeChange}
                                checked={mergeType === "DISTINCT"}
                                label="Chosen value can be in ANY selection (OR statement)"
                                style={{marginLeft: "1.8rem"}}
                            />
                            <br/>
                            <RadioButton
                                name="mergeType"
                                value="COMMON"
                                onChange={handleMergeTypeChange}
                                checked={mergeType === "COMMON"}
                                label="Chosen value can be in ALL selections (AND statement)"
                                style={{marginLeft: "1.8rem"}}
                            />
                        </div>
                        <div style={{paddingTop: "1.2rem"}}>
                            <Label
                                className={"field-label left-of-field-label"}
                            >
                                Target Type
                                {/*<span className="required-field">*</span>*/}
                            </Label>



                            {/*<label htmlFor="target_type" className="k-radio-label"*/}
                            {/*       style={{marginLeft: "2rem", paddingRight: "1.8rem", fontSize: "1.1rem"}}>*/}
                            {/*    <b>Target Type</b>*/}
                            {/*    <span class="text-danger">*</span>*/}
                            {/*</label>*/}


                            <RadioButton
                                name="targetType"
                                value="Consumer"
                                onChange={handleTargetTypeChange}
                                checked={targetType === "CONSUMER"}
                                label="Consumer"
                                disabled={true}
                                style={{marginLeft: "1.8rem"}}
                            />
                            <RadioButton
                                name="targetType"
                                value="Business"
                                onChange={handleTargetTypeChange}
                                checked={targetType === "BUSINESS"}
                                label="Business"
                                style={{marginLeft: "1.35rem"}}
                                disabled={true}
                            />
                        </div>
                        <div style={{paddingTop: "1.8rem"}}>
                            <div>
                                <Label
                                    className={"field-label"}
                                >
                                    New Segment Name
                                    {/*<span className="required-field">*</span>*/}
                                </Label>

                                {/*<label style={{*/}
                                {/*    marginLeft: "2rem",*/}
                                {/*    fontSize: "1.1rem",*/}
                                {/*    paddingBottom: ".5rem",*/}
                                {/*    color: !isValid ? "red" : "black"*/}
                                {/*}}>*/}
                                {/*    <b>New Segment Name</b>*/}
                                {/*    <span class="text-danger">*</span>*/}
                                {/*</label>*/}


                            </div>
                            <Input name="newSegmentName"
                                   minLength={3}
                                   maxLength={64}
                                   value={segmentName}
                                   onChange={handleSegmentNameChange}
                                   placeholder={"myNewMergedSegment..."}
                                   style={{
                                       // marginLeft: "2rem",
                                       // width: "85%",
                                       borderColor: !isValid ? "#00424D" : ""
                                   }}
                            />
                            <div style={{display: !isValid ? '' : "none"}}>
                                {/*<div style={{display: ''}}>*/}
                                <span name={"msg"}
                                      style={{
                                          // marginLeft: "2rem",
                                          // color: !isValid ? "#00424D" : "none",
                                          color: "#00424D"
                                      }}
                                    // className={"hint-standard"}
                                >
                                    {REQUIRED_MESSAGE}
                            </span>
                            </div>
                        </div>
                        <div style={{paddingTop: "1.8rem"}}>
                            <div>
                                <Label
                                    className={"field-label"}
                                >
                                    Save to Group
                                    {/*<span className="required-field">*</span>*/}
                                </Label>


                                {/*<label style={{marginLeft: "2rem", fontSize: "1.1rem", paddingBottom: ".5rem"}}>*/}
                                {/*    <b>Save to Group</b>*/}
                                {/*    <span class="text-danger">*</span>*/}
                                {/*</label>*/}
                            </div>
                            <DropDownList
                                // style={{marginLeft: "2rem", width: "85%"}}
                                data={accessibleGroups}
                                textField="group_name"
                                value={groupValue}
                                onChange={handleGroupChange}
                                className={"dropdown-standard"}
                            />
                        </div>
                        <div style={{paddingTop: "1.8rem"}}>
                            <div>
                                <Label
                                    className={"field-label"}
                                >
                                    Notes
                                    {/*<span className="required-field">*</span>*/}
                                </Label>

                                {/*<label style={{marginLeft: "2rem", fontSize: "1.1rem", paddingBottom: ".5rem"}}>*/}
                                {/*    <b>Notes</b>*/}
                                {/*</label>*/}
                            </div>
                            <TextArea
                                value={notes}
                                // style={{marginLeft: "2rem", width: "85%"}}
                                maxLength={5000}
                                placeholder={"Targeting new customers from mailing list..."}
                                rows={5}
                                onChange={handleNotesChange}
                            />
                            {/*<span style={{marginLeft: "2rem", paddingTop: ".8rem"}}>{NOTES_OPTIONAL_MESSAGE}</span>*/}
                            <span className={"hint-standard"}>{NOTES_OPTIONAL_MESSAGE}</span>
                        </div>
                        <div
                            //     style={{
                            //     marginLeft: "2rem",
                            //     paddingTop: "1.2rem",
                            //     paddingBottom: ".5rem",
                            //     fontSize: "1.1rem"
                            // }}
                            style={{
                                // marginLeft: "2rem",
                                paddingTop: "1.2rem",
                                paddingBottom: ".5rem",
                                fontSize: "14px"
                            }}
                        >
                            <span><b>The following segments will be merged to create a new segment:</b></span>
                        </div>
                        {/*<ul style={{marginLeft: "2.2rem"}}>*/}
                        <ul >
                            {segments}
                        </ul>
                    </LoadOverlay>
                    <DialogActionsBar layout={"end"}>
                        <Button
                            // className="dialog-cancel"
                            className={"button-standard button-white"}
                            onClick={() => {
                                props.cancelSubscriber(undefined)
                            }}>
                            Cancel
                        </Button>
                        <Button
                            // className="dialog-save"
                            className={"button-standard button-submit"}
                            disabled={disableMerge}
                            onClick={mergeSegments}>
                            Save
                        </Button>
                    </DialogActionsBar>
                </Dialog>)}
            </div>
        </>
    );
}

export default TreeMergeSegments;
