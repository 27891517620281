import React, {useEffect, useState} from "react";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../../Utils/Common";
import {
    ACCOUNT_DETAILS_API,
    ADMIN_ACCOUNT_DETAILS_API,
    ALL_ACCOUNT_DETAILS,
    CUSTOMER_SUCCESS_IDs,
    DORMANT_USERS_API,
    SUCCESS_STATUS,
    USER,
} from "../../../api/constants";
import {schema} from "../../../helpers/AccountSchema";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import DynamicAlert from "../../Components/SharedComponents/DynamicAlert";
import LoadOverlay from "../../Components/SharedComponents/LoadOverlay";
import usStatesData from "./usStates.json";
import countryData from './countries.json'; // Import the JSON file
import "./AccountTabStyles.css";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Button} from "@progress/kendo-react-buttons";

function AccountsDetailsSetup(props) {
    const { id, accountCompany, setShowEditCreate, refreshTable, isAccountCreate, setOverlayActive, isEditable } = props;

    console.log("AccountsDetailsSetupProps=", props);
    const adminUser = JSON.parse(sessionStorage.getItem(USER)) || null
    const [contactSubstUsers, setContactSubstUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [msg, setMsg] = useState("");
    const [type, setType] = useState("");
    const [selectedSubstUser, setSelectedSubstUser] = useState(null); // State for selected substitute user
    const [accountMap, setAccountMap] = useState({});
    const options = [
        {text: 'ADS-Premium', value: "1"},
        {text: 'ADS-One', value: "0"}
    ];
    const [originalFounderId, setOriginalFounderId] = useState(null);
    const [customerSuccessIds, setCustomerSuccessIds] = useState([]);
    const [confirmDialog, setConfirmDialog] = React.useState(false);
    const [isAccountActivated, setIsAccountActivated] = React.useState(true);

    useEffect(() => {
        if (id) {
            getDetails(id);
            setOriginalFounderId(id);
        } else {
            resetFormValues();
        }
    }, [id]);

    useEffect(() => {
        console.log('isAccountActivated:', isAccountActivated);
    }, [isAccountActivated]);
    const onSubmit = (data) => {
        // Perform any additional logging or processing here
        console.log("Form data submitted:", data);

        // Access errors from formState
        const validationErrors = errors; // This is already provided by useForm

        // Log errors to console
        if (validationErrors && Object.keys(validationErrors).length > 0) {
            console.log("Validation errors:", validationErrors);
        }

        // Continue with your submit logic
        submitDetails(data);
    };

    const {
        register,
        handleSubmit,
        formState: {errors},
        reset,
        setValue,
        watch
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            address: "",
            city: "",
            state: "",
            country: "",
            zip_code: "",
            billing_contact: "",
            billing_email: "",
            billing_phone: "",
            contact_first_name: "",
            contact_last_name: "",
            contact_email: "",
            company: "",
            ads_type: "",
            original_founder_id: null,
            founding_user_id: null,  //the value in case of substitution
            salesforce_id: "",
            intacct_id: "",
            customer_success_user_id: null,
            postal_code: ""
        },
    });

    // //const [isDeactivated, setIsDeactivated] = React.useState(false);
    // React.useEffect(() => {
    //     // This effect will run whenever isDeactivated changes
    //     console.log("isDeactivatedUpdatedTo:", isDeactivated);
    // }, [isDeactivated]);

    const submitDetails = async (data) => {
        // Convert customer_success_user_id to number if needed
        console.log("submitDetailsData=", data);
        data.customer_success_user_id = Number(data.customer_success_user_id);

        // Determine the URL based on whether it's a create or update operation
        let url = ADMIN_ACCOUNT_DETAILS_API + "/" + id;
        if (isAccountCreate) {
            url = ADMIN_ACCOUNT_DETAILS_API;
        }

        // Add deactivate = true to data if isDeactivated is true
        //console.log("data.deactivate=", data.deactivate);

        // Start loading overlay
        setOverlayActive(true);

        try {
            let res;
            if (isAccountCreate) {
                // POST request for account creation
                res = await semcastAxios.post(url, data, { withCredentials: true });
                if (res.data.status === "success") {
                    showSuccessToastMessage("Success! The account has been created");
                    setShowEditCreate(false);
                    refreshTable();
                } else {
                    showErrorToastMessage("Error occurred: The account has not been created");
                }
            } else {
                // PUT request for account update
                console.log("PutData=", data);
                res = await semcastAxios.put(url, data, { withCredentials: true });
                if (res.data.status === "success") {
                    showSuccessToastMessage("Success! The account has been updated");
                    setShowEditCreate(false);
                    refreshTable();
                } else {
                    showErrorToastMessage("Error occurred: The account has not been updated");
                }
            }
        } catch (error) {
            showErrorToastMessage("Error occurred: " + error.message);
        } finally {
            // Always hide loading overlay after request completes
            setOverlayActive(false);
        }
    };

    const handleCancel = () => {
        // if (originalFounderId) {
        //     getDetails(originalFounderId);
        // } else {
        //     resetFormValues();
        // }
        //navigate(-1);
        //setShowPricing(false);
        setShowEditCreate(false);
        setOriginalFounderId(null);
    };
    const loadAllUsers = async () => {
        // console.log("loadSubstUsersIndexNo=", indexNo);
        let path = DORMANT_USERS_API +
            `?deleted_at=null`;
        // console.log("loadAccountUsersPath=", path);
        const res = await semcastAxios.get(path, {
            withCredentials: true,
        }).catch((err) => {
            // console.log(err);
            showErrorToastMessage(err, 10);
        });
        return res;
    }
    const loadSubstUsers = async () => {
        const res = await loadAllUsers();
        if (res.data.status === SUCCESS_STATUS) {
            // console.log("loadSubstUsersResDataData=", res?.data?.data);
            if (res?.data?.data?.length) {
                let currentUserEmail = accountMap[id]?.email;
                // console.log("currentUserEmail=", currentUserEmail);
                let filteredUsers = res?.data?.data.filter(user => {
                    return user.founder_id === id && user.email !== currentUserEmail;
                });
                if (filteredUsers?.length === 0) {
                    showErrorToastMessage("Error occurred: The contact substitution users are not found");
                    return;
                }
                // console.log("filteredUsers=", filteredUsers);
                setContactSubstUsers(filteredUsers);
            } else {
                showErrorToastMessage("Error occurred: The users are not found");
            }
        } else {
            showErrorToastMessage("Error occurred: The users are not found");
        }
    };

    const handleSubstUserChange = (e) => {
        // Get the selected substitute user's information
        console.log("handleSubstUserChange e=", e);
        console.log("handleSubstUserChange e.target.value=", e.target.value);
        const selectedUserId = e.target.value;
        if (!selectedUserId) {
            setContactSubstUsers([]);
            setValue('founding_user_id', Number(originalFounderId));
            // setValue('contact_first_name', selectedUser.first_name);
            // setValue('contact_last_name', selectedUser.last_name);
            // setValue('contact_email', selectedUser.email);
            return;
        }
        const selectedUser = contactSubstUsers.find((user) => user.id == selectedUserId);
        console.log("selectedUser=", selectedUser);
        setValue('contact_first_name', selectedUser.first_name);
        setValue('contact_last_name', selectedUser.last_name);
        setValue('contact_email', selectedUser.email);
        setValue('founding_user_id', Number(selectedUserId));
        setValue('original_founder_id', Number(originalFounderId));
        // Update the contact fields with the selected user's information
        setSelectedSubstUser(selectedUser);
        //setContactSubstUsers([]);
    };

    useEffect(() => {

        if (!isAccountCreate) {
            setValue("original_founder_id", Number(originalFounderId));
            console.log("1originalFounderId=", originalFounderId);
        }}, [originalFounderId]);

    useEffect(() => {
            setValue("original_founder_id", Number(originalFounderId));
            console.log("2originalFounderId=", originalFounderId);
        }, [originalFounderId]);

    useEffect(() => {
        // console.log(isAccountCreate)
        if (isAccountCreate) {
            setValue('ads_type', '1');
        }
    }, [isAccountCreate]);

    const get = async () => {
        console.log("Calling API");
        try {
            setLoading(true)
            const res = await semcastAxios.get(ALL_ACCOUNT_DETAILS, {
                withCredentials: true,
            });
            if (res.data.status === "success") {
                let accountDataMap = {};
                res.data.data.forEach((account) => {
                    accountDataMap[account.id] = account;
                });

                setAccountMap(accountDataMap);
                console.log("accountDataMap=", accountDataMap);
                console.log("accountMap=", accountMap);
            }
        } catch (error) {
            console.error("Error occurred:", error);
        } finally {
            setLoading(false)
        }
    };

    const getDetails = async (index) => {
        console.log("getDetailsIndex=", index);
        setAlert(false);
        setContactSubstUsers([]);
        setLoading(true);
        let path = null;
        try {
            if (isEditable) {   //admin
                path = ADMIN_ACCOUNT_DETAILS_API + "/" + index;
            } else {
                path = ACCOUNT_DETAILS_API;  // not admin
            }
            console.log("getDetailsPath=", path);
            await semcastAxios
//                .get(ADMIN_ACCOUNT_DETAILS_API + "/" + index, {
                .get(path,  {withCredentials: true,})
                .then((res) => {
                    let ads = res.data.data.ads_type.toString();
                    reset(res.data.data);
                    //console.log("res.data.data.ads_type=", ads);
                    console.log("res.data.data=", res.data.data);

                    const deactivatedAt = res.data.data.deactivated_at;

                    // Only update state if it has changed
                    setIsAccountActivated((prevState) => {
                        const newState = deactivatedAt === null;
                        return prevState !== newState ? newState : prevState;
                    });

                    if (ads === '1') {
                        setValue('ads_type', '1');
                    } else if (ads === '0') {
                        setValue('ads_type', '0');
                    }
                    // setLoading(false);
                    // setType("Success");
                    // setAlert(true);
                })
                .catch((err) => {
                    setType("Error!");
                    setLoading(false);
                    setAlert(true);
                    // console.log("getDetailsError=", err);
                    if (err?.response?.data?.data) {
                        setMsg(err.response.data.data);
                    } else {
                        setMsg("Error occurred: The account can't be retrieved");
                    }
                    showErrorToastMessage("Error occurred: The account can't be retrieved");
                    showErrorToastMessage(err, 10);
                });
        } catch (error) {
            console.error("getDetailsError occurred:", error);
        } finally {
            setLoading(false)
        }
    };

    const getCustomerSuccessIDs = async () => {

        try {
            await semcastAxios
                .get(CUSTOMER_SUCCESS_IDs, {withCredentials: true})
                .then((res) => {
                    setCustomerSuccessIds(res?.data.data);
                })
                .catch((err) => {
                    showErrorToastMessage(err + " Please contact support at adssupport@semcasting.com.", 10);
                });
        } catch (error) {
            showErrorToastMessage(error + " Please contact support at adssupport@semcasting.com.", 10);
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        get();
        getCustomerSuccessIDs();
    }, []);

    //for some reason reset is not working on the form
    const resetFormValues = () => {
        setValue('contact_first_name', "");
        setValue('contact_last_name', "");
        setValue('contact_email', "");
        setValue('address', "");
        setValue('city', "");
        setValue('state', "");
        setValue('country', "");
        setValue('zip_code', "");
        setValue('billing_contact', "");
        setValue('billing_email', "");
        setValue('billing_phone', "");
        setValue('company', "");
        setValue('ads_type', "");
        setValue('original_founder_id', "");
        setValue('salesforce_id', "");
        setValue('intacct_id', "");
        setValue('customer_success_user_id', "");
    }
    const country = watch('country', 'US'); // Default to US

    // const handleDeactivate = () => {
    //     setIsDeactivated(true); // Set isDeactivated to true
    //     console.log("SettingIsDeactivated=", true); // Log the intended state
    //     setShowModal(true);
    // };
    const handleActivateAccount = () => {
        console.log("StartHandleActivateAccount")
        //setConfirmDialog(true)
        handleSubmit((data) => {
            data.deactivated_at = isAccountActivated ? new Date(): null;
            submitDetails(data).then(() => {
                setConfirmDialog(false)
            });
        })();
    }

    return (
        <>
            {/*{adminUser?.isAdmin && <div className="content-body">*/}
            {(adminUser?.isAdmin || !isEditable) && <div>
                <LoadOverlay active={loading}>
                    <div className="container-fluid admin-create-account">
                        <div className="row">
                            {/*<form className={!isEditable ? 'disabled-form' : ''} onSubmit={handleSubmit((data) => submitDetails(data))}>*/}
                            <form className={!isEditable ? 'disabled-form' : ''} onSubmit={handleSubmit(onSubmit)}>

                            <div className="col-12 col-md-12 col-lg-12">
                                    {alert ? <DynamicAlert type={type} message={msg}/> : null}
                                    <div className="section-card section-card-inner-margins user-info-section">
                                        <div className="card-body">
                                            <div className={"card-title"}>User Info</div>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    {isAccountCreate &&
                                                        <div className="col-md-4" style={{display: "none"}}>
                                                            <div className="form-group">
                                                                <label className="form-label required-asterisk">ADS
                                                                    Type</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control input-rounded"
                                                                    placeholder=""
                                                                    name="ads_type"
                                                                    {...register("ads_type")}
                                                                />
                                                            </div>
                                                        </div>}
                                                    <div className="col-md-4" style={{display: "none"}}>
                                                        <div className="form-group">
                                                            <label className="form-label required-asterisk">Original
                                                                Founder
                                                                ID</label>
                                                            <input
                                                                type="number"
                                                                className="form-control input-rounded"
                                                                placeholder=""
                                                                name="original_founder_id"
                                                                {...register("original_founder_id")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4" style={{display: "none"}}>
                                                        <div className="form-group">
                                                            <label className="form-label required-asterisk">New
                                                                Founder
                                                                ID</label>
                                                            <input
                                                                type="number"
                                                                className="form-control input-rounded"
                                                                placeholder=""
                                                                name="founding_user_id"
                                                                {...register("founding_user_id")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-label required-asterisk">Contact
                                                                First Name</label>
                                                            <input
                                                                type="text"
                                                                required
                                                                className="form-control input-rounded"
                                                                placeholder=""
                                                                name="contact_first_name"
                                                                {...register("contact_first_name")}
                                                            />
                                                            <span className="validation-error">
                                                              {errors.contact_first_name
                                                                  ? errors.contact_first_name.message
                                                                  : null}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-label required-asterisk">Contact
                                                                Last Name</label>
                                                            <input
                                                                type="text"
                                                                required
                                                                className="form-control input-rounded"
                                                                placeholder=""
                                                                name="contact_last_name"
                                                                {...register("contact_last_name")}
                                                            />
                                                            <span className="validation-error">
                                                              {errors.contact_last_name
                                                                  ? errors.contact_last_name.message
                                                                  : null}
                                                            </span>
                                                        </div>
                                                        {/* Render the select dropdown for substitute users */}

                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-label required-asterisk">Contact
                                                                Email</label>

                                                            <input
                                                                type="text"
                                                                required
                                                                className="form-control input-rounded"
                                                                placeholder=""
                                                                name="contact_email"
                                                                {...register("contact_email")}
                                                            />
                                                            <span className="validation-error">
                                                              {errors.contact_email
                                                                  ? errors.contact_email.message
                                                                  : null}
                                                            </span>

                                                            {isEditable && contactSubstUsers.length === 0 && !isAccountCreate &&
                                                                (<button className={"btn btn-primary float-right"}
                                                                         type="button" onClick={loadSubstUsers}>
                                                                    Change Founding/Contact User</button>)
                                                            }
                                                            {contactSubstUsers.length > 0 && !isAccountCreate && (
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label className="form-label">Select Substitute</label>
                                                                        <select
                                                                            className="form-control form-control-custom form-select"
                                                                            value={selectedSubstUser ? selectedSubstUser.id : ''}
                                                                            onChange={handleSubstUserChange}
                                                                        >
                                                                            <option value="" >...
                                                                            </option>
                                                                            {contactSubstUsers.map((user) => (
                                                                                // <option key={user.id}
                                                                                //         value={user.id}>
                                                                                //     {user.first_name} {user.last_name} ({user.email})
                                                                                // </option>
                                                                                <option key={user.id} value={user.id} selected={selectedSubstUser?.id === user.id}>
                                                                            {user.first_name} {user.last_name} ({user.email})
                                                                        </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*1*/}
                                    <div className="section-card section-card-inner-margins">
                                        {/*2*/}
                                        <div className="card-body">
                                            <div className={"card-title"}>Company Info</div>
                                            {/*3*/}
                                            <div className="col-md-12 mt-3 p-0 mb-3">
                                                {/*4*/}
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <label className="form-label required-asterisk">Company Name</label>
                                                            <input
                                                                type="text"
                                                                required
                                                                className="form-control input-rounded"
                                                                placeholder=""
                                                                name="company"
                                                                {...register("company")}
                                                            />
                                                            <span className="validation-error">
                    {errors.company ? errors.company.message : null}
                  </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <label className="form-label required-asterisk">Address</label>
                                                            <div style={{ width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
                                                                <input
                                                                    type="text"
                                                                    required
                                                                    className="form-control input-rounded"
                                                                    placeholder=""
                                                                    name="address"
                                                                    {...register("address")}
                                                                    style={{ width: '100%', overflowX: 'auto', whiteSpace: 'nowrap', paddingRight: '20px' }}
                                                                />
                                                            </div>
                                                            <span className="validation-error">
            {errors.address ? errors.address.message : null}
        </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <label className="form-label required-asterisk">City</label>
                                                            <input
                                                                type="text"
                                                                required
                                                                className="form-control input-rounded"
                                                                placeholder=""
                                                                name="city"
                                                                {...register("city")}
                                                            />
                                                            <span className="validation-error">
                    {errors.city ? errors.city.message : null}
                  </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <label className="form-label required-asterisk">Country</label>
                                                            <select
                                                                required
                                                                className="form-control input-rounded"
                                                                {...register("country")}
                                                            >
                                                                {countryData.map((country) => (
                                                                    <option key={country.value} value={country.value}>
                                                                        {country.label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <span className="validation-error">
                    {errors.country ? errors.country.message : null}
                  </span>
                                                        </div>
                                                    </div>
                                                    {country === 'US' ? (
                                                        <>
                                                            <div className="col-4">
                                                                <div className="form-group">
                                                                    <label className="form-label required-asterisk">State</label>
                                                                    <select
                                                                        required
                                                                        className="form-control input-rounded"
                                                                        {...register("state")}
                                                                    >
                                                                        {usStatesData.map((state) => (
                                                                            <option key={state.value} value={state.value}>
                                                                                {state.label}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    <span className="validation-error">
                        {errors.state ? errors.state.message : null}
                      </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="form-group">
                                                                    <label className="form-label required-asterisk">Zip Code</label>
                                                                    <input
                                                                        type="text"
                                                                        required
                                                                        className="form-control input-rounded"
                                                                        placeholder=""
                                                                        name="zip_code"
                                                                        {...register("zip_code")}
                                                                    />
                                                                    <span className="validation-error">
                        {errors.zip_code ? errors.zip_code.message : null}
                      </span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ):
                                                        (
                                                                                              /* US-specific code ends */

                                                                                              /* International code begins */
                                                                                              <>
                                                                                              <div className="col-4">
                                                                                              <div className="form-group">
                                                                                              <label className="form-label">State/Province/Region</label>
                                                                                              <input
                                                                                              type="text"
                                                                                              className="form-control input-rounded"
                                                                                              placeholder=""
                                                                                              name="state"
                                                                                          {...register("state")}
                                                                                      />
                                                                                      <span className="validation-error">
                                                              {errors.state ? errors.state.message : null}
                                                            </span>
                                                                                  </div>
                                                                              </div>
                                                                              <div className="col-4">
                                                                                  <div className="form-group">
                                                                                      <label className="form-label">Postal Code</label>
                                                                                      <input
                                                                                          type="text"
                                                                                          className="form-control input-rounded"
                                                                                          placeholder=""
                                                                                          name="postal_code"
                                                                                          {...register("postal_code")}
                                                                                      />
                                                                                      <span className="validation-error">
                                                              {errors.postal_code ? errors.postal_code.message : null}
                                                            </span>
                                                                                  </div>
                                                                              </div>
                                                                          </>
                                                                          /* International code ends */
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="section-card section-card-inner-margins">
                                        <div className="card-body">
                                            <div className={"card-title"}>Billing Info</div>
                                            <div className="col-md-12 mt-3 p-0 mb-3">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <label className="form-label required-asterisk">Billing
                                                                Contact</label>
                                                            <input
                                                                type="text"
                                                                required
                                                                className="form-control input-rounded"
                                                                placeholder=""
                                                                name="billing_contact"
                                                                {...register("billing_contact")}
                                                            />
                                                            <span className="validation-error">
                                                                      {errors.billing_contact
                                                                          ? errors.billing_contact.message
                                                                          : null}
                                                                    </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <label className="form-label required-asterisk">Billing
                                                                Email</label>
                                                            <input
                                                                type="text"
                                                                required
                                                                className="form-control input-rounded"
                                                                placeholder=""
                                                                name="billing_email"
                                                                {...register("billing_email")}
                                                            />
                                                            <span className="validation-error">
                                                                      {errors.billing_email
                                                                          ? errors.billing_email.message
                                                                          : null}
                                                                    </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <label className="form-label required-asterisk">Billing
                                                                Phone Number</label>
                                                            <input
                                                                type="text"
                                                                required
                                                                className="form-control input-rounded"
                                                                placeholder=""
                                                                name="billing_phone"
                                                                {...register("billing_phone")}
                                                            />
                                                            <span className="validation-error">
                                                                      {errors.billing_phone
                                                                          ? errors.billing_phone.message
                                                                          : null}
                                                                    </span>
                                                        </div>
                                                    </div>
                                                    {!isAccountCreate && <div
                                                        className={"col-4 account-details-ads-type-div field-left-margin"}>
                                                        <label className={"field-label required-asterisk"}>ADS
                                                            Product
                                                            Type</label>
                                                        <select
                                                            className="form-control form-control-custom form-select"
                                                            {...register("ads_type")} >
                                                            {options.map((option) => (
                                                                <option key={option.value} value={option.value}>
                                                                    {option.text}
                                                                </option>
                                                            ))}
                                                        </select>

                                                        <span className="validation-error">
                                                                  {errors.ads_type
                                                                      ? errors.ads_type.message
                                                                      : null}
                                                                </span>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {adminUser?.isAdmin &&  <div className="section-card section-card-inner-margins">
                                        <div className="card-body">
                                            <div className={"card-title"}>Other Info</div>
                                            <div className="col-md-12 mt-3 p-0 mb-3">
                                                <div className="row">
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <label className="form-label">Salesforce ID</label>
                                                            <input
                                                                type="text"
                                                                className="form-control input-rounded"
                                                                placeholder=""
                                                                name="salesforce_id"
                                                                {...register("salesforce_id")}
                                                            />
                                                            <span className="validation-error">
                                                                      {errors.salesforce_id
                                                                          ? errors.salesforce_id.message
                                                                          : null}
                                                                    </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="form-group">
                                                            <label className="form-label">Intacct ID</label>
                                                            <input
                                                                type="text"
                                                                className="form-control input-rounded"
                                                                placeholder=""
                                                                name="intacct_id"
                                                                {...register("intacct_id")}
                                                            />
                                                            <span className="validation-error">
                                                                      {errors.intacct_id
                                                                          ? errors.intacct_id.message
                                                                          : null}
                                                                    </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={"col-4 account-details-ads-type-div field-left-margin"}>
                                                        <label className={"form-label"}>Customer Success User
                                                            ID</label>
                                                        <select
                                                            className="form-control form-control-custom form-select"
                                                            {...register("customer_success_user_id")} >
                                                            {isEditable && (<option value="0" selected>Please select</option>)}
                                                            {customerSuccessIds.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.first_name} {option.last_name} ({option.email})
                                                                </option>
                                                            ))}
                                                        </select>

                                                        <span className="validation-error">
                                                                  {errors.customer_success_user_id
                                                                      ? errors.customer_success_user_id.message
                                                                      : null}
                                                                </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {isEditable && (<div className="col-12 text-right mb-4">
                                        {errors && Object.keys(errors).length > 0 && (
                                            <div>
                                                <p>Please correct the following errors:</p>
                                                <ul>
                                                    {Object.values(errors).map((error, index) => (
                                                        <li key={index}>{error.message}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                            {confirmDialog && (
                                                <div className={"admin-users-delete-dialog-container"}>
                                                    <Dialog title={isAccountActivated ? "Deactivate Account" : "Activate Account"} onClose={() => setConfirmDialog(false)} className={"admin-users-delete-dialog dialog-standard"}>
                                                        <p className={"admin-users-delete-dialog-message-text"}>
                                                            Are you sure that you want to {isAccountActivated ? "deactivate" : 'activate'} the account {accountCompany}? {isAccountActivated ? "Deactivating" : 'Activate'}  account will also {isAccountActivated ? "deactivate" : 'activate'} all users belonging to this account.
                                                        </p>
                                                        <DialogActionsBar>
                                                            <Button className="button-standard button-white" onClick={() => setConfirmDialog(false)}>No</Button>
                                                            <Button className="button-standard button-submit" onClick={handleActivateAccount}>Yes</Button>
                                                        </DialogActionsBar>
                                                    </Dialog>
                                                </div>
                                            )}
                                            {/*end isDeactivated*/}

                                            <div className="button-group d-flex justify-content-between">
                                                <div>

                                                    {isAccountActivated && (<button
                                                    type="button"
                                                    className="btn  btn-danger"
                                                    onClick={()=>setConfirmDialog(true)}
                                                >
                                                    Deactivate
                                                </button> )}


                                                    {!isAccountActivated && (<button
                                                        type="button"
                                                        className="btn  btn-primary"
                                                        onClick={()=>setConfirmDialog(true)}
                                                    >
                                                        Activate
                                                    </button> )}
                                                </div>
                                                <div>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={handleCancel}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >
                                                    Save
                                                </button>
                                                </div>
                                            </div>
                                      </div>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </LoadOverlay>
                {/***********************************
                 Content body end
                 ************************************/}
            </div>
            }
        </>
    );
}

export default AccountsDetailsSetup;
