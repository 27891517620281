import {
  accountPermissions,
  accountPricing,
  fetchAlreadyActivatedData,
  fetchSavableFieldsPreFillData,
  loadUploadedRecordsData,
} from "../actions/ActivateSegmentActions";
import {
  ACTIVATE_SEGMENT,
  COMPANY_ID_PLACE_HOLDER,
  HIDDEN_ONBOARDING_COMPANIES, SEMCASTING_TILES, USER,
} from "../api/constants";
import { UDX } from "../Utils/UDX";
import moment from "moment/moment";
import { priceFormat, semcastAxios } from "../Utils/Common";
import { facebookConfig } from "../Admin/Components/Segmentation/PlatformConfigs/facebook";
import { adformConfig } from "../Admin/Components/Segmentation/PlatformConfigs/adform";
import { adobeConfig } from "../Admin/Components/Segmentation/PlatformConfigs/adobe";
import { amazonConfig } from "../Admin/Components/Segmentation/PlatformConfigs/amazon";
import { amobeeConfig } from "../Admin/Components/Segmentation/PlatformConfigs/amobee";
import { yahooConfig } from "../Admin/Components/Segmentation/PlatformConfigs/yahoo";
import { googleConfig } from "../Admin/Components/Segmentation/PlatformConfigs/google";
import { liverampConfig } from "../Admin/Components/Segmentation/PlatformConfigs/liveramp";
import { rokuConfig } from "../Admin/Components/Segmentation/PlatformConfigs/roku";
import { semcastingConfig } from "../Admin/Components/Segmentation/PlatformConfigs/semcasting";
import { springserveConfig } from "../Admin/Components/Segmentation/PlatformConfigs/springserve";
import { ttdConfig } from "../Admin/Components/Segmentation/PlatformConfigs/ttd";
import { xandrConfig } from "../Admin/Components/Segmentation/PlatformConfigs/xandr";
import { tvsciConfig } from "../Admin/Components/Segmentation/PlatformConfigs/tvsci";
import { iqmConfig} from "../Admin/Components/Segmentation/PlatformConfigs/iqm";
//New
import { basisConfig } from "../Admin/Components/Segmentation/PlatformConfigs/basis";
import { stackadaptConfig } from "../Admin/Components/Segmentation/PlatformConfigs/stackadapt";
import { semcastingadobeConfig } from "../Admin/Components/Segmentation/PlatformConfigs/semcastingadobe";
import { semcastingamobeeConfig } from "../Admin/Components/Segmentation/PlatformConfigs/semcastingamobee";
import { semcastingttdConfig } from "../Admin/Components/Segmentation/PlatformConfigs/semcastingttd";
import { semcastingttd3rdpartyConfig } from "../Admin/Components/Segmentation/PlatformConfigs/semcastingttd3rdparty";
import { semcastingxandrConfig } from "../Admin/Components/Segmentation/PlatformConfigs/semcastingxandr";
import { xandrttdConfig } from "../Admin/Components/Segmentation/PlatformConfigs/xandrttd";
import { xandrdv360Config } from "../Admin/Components/Segmentation/PlatformConfigs/xandrdv360";
// import { pinterestConfig } from "../Admin/Components/Segmentation/PlatformConfigs/pinterest";
import { keywordswhitelistConfig } from "../Admin/Components/Segmentation/PlatformConfigs/keywordswhitelist";
import {pubmaticConfig} from "../Admin/Components/Segmentation/PlatformConfigs/pubmatic";
import {semcastingmeasurementConfig} from "../Admin/Components/Segmentation/PlatformConfigs/semcastingmeasurement";
import {googleadsConfig} from "../Admin/Components/Segmentation/PlatformConfigs/googleads";
import {tiktokConfig} from "../Admin/Components/Segmentation/PlatformConfigs/tiktok";
import {adsAllInOneConfig} from "../Admin/Components/Segmentation/PlatformConfigs/adsallinone";
// import {clearLineAdsAllInOneConfig} from "../Admin/Components/Segmentation/PlatformConfigs/clearlineadsallinone";
import {digitalremedyConfig} from "../Admin/Components/Segmentation/PlatformConfigs/digitalremedy";
import {semcastingdirectmailConfig} from "../Admin/Components/Segmentation/PlatformConfigs/semcastingdirectmail";
import {instepmeasurementConfig} from "../Admin/Components/Segmentation/PlatformConfigs/instepmeasurement";
import {jamLoopConfig} from "../Admin/Components/Segmentation/PlatformConfigs/jamloop";
import {oneviewSnowflakeConfig} from "../Admin/Components/Segmentation/PlatformConfigs/oneviewsnowflake";
import {freewheelConfig} from "../Admin/Components/Segmentation/PlatformConfigs/freewheel";
import {anzuConfig} from "../Admin/Components/Segmentation/PlatformConfigs/anzu";
import {openxConfig} from "../Admin/Components/Segmentation/PlatformConfigs/openx";
import {grabitmediaConfig} from "../Admin/Components/Segmentation/PlatformConfigs/grabitmedia";
import {adelphicConfig} from "../Admin/Components/Segmentation/PlatformConfigs/adelphic";
//List in alphabetical order
// export const COMPANIES = {
//   adform: adformConfig,
//   adobe: adobeConfig,
//   amazon: amazonConfig,
//   amobee: amobeeConfig,
//   basis: basisConfig,
//   digitalremedy: digitalremedyConfig,
//   fundprogress: fundprogressConfig,
//   google: googleConfig,
//   googleads: googleadsConfig,
//   facebook: facebookConfig,
//   keywordswhitelist: keywordswhitelistConfig,
//   liveramp: liverampConfig,
//   roku: rokuConfig,
//   pinterest: pinterestConfig,
//   pubmatic: pubmaticConfig,
//   springserve: springserveConfig,
//   stackadapt: stackadaptConfig,
//   tiktok:tiktokConfig,   //enabled for Tiktok activation
//   ttd: ttdConfig,
//   xandr: xandrConfig,
//   yahoo: yahooConfig,
//   semcasting: semcastingConfig,
//   semcastingadobe: semcastingadobeConfig,
//   semcastingamobee: semcastingamobeeConfig,
//   semcastingmeasurement: semcastingmeasurementConfig,
//   semcastingttd: semcastingttdConfig,
//   semcastingttd3rdparty: semcastingttd3rdpartyConfig,
//   semcastingxandr: semcastingxandrConfig,
// };

// export const curateCOMPANIES = {
//   xandrdv360: xandrdv360Config,
//   xandrttd: xandrttdConfig,
// };
//
// export const dspCONNECTIONS = {
//   tvsci: tvsciConfig,
// };
export const AllInOneTiles = {
  adsallinone:adsAllInOneConfig,
  // FD:9894 clearlineadsallinone:clearLineAdsAllInOneConfig
}
export const DirectIntegrationsTiles = {
  adelphic: adelphicConfig, //FD#12436
  adform: adformConfig,
  adobe: adobeConfig,
  amobee: amobeeConfig,
  anzu: anzuConfig,
  ttd: ttdConfig,
  xandr: xandrConfig,
  yahoo: yahooConfig,
  google: googleConfig,
  googleads: googleadsConfig,
  // FD: 10378
  // digitalremedy: digitalremedyConfig,
  // fundprogress: fundprogressConfig,
  roku: rokuConfig,
  keywordswhitelist: keywordswhitelistConfig,
  // FD: 10378
  // semcasting: semcastingConfig,
  iqm:iqmConfig,
  // FD: 10378
  stackadapt: stackadaptConfig,
  jamloop: jamLoopConfig,
  oneviewsnowflake: oneviewSnowflakeConfig,
  freewheel: freewheelConfig

}
export const SocialTiles = {
  facebook: facebookConfig,
  // pinterest: pinterestConfig,
  tiktok:tiktokConfig,   //enabled for Tiktok activation
}
export const SupplySideTiles = {
  springserve: springserveConfig,
  pubmatic: pubmaticConfig,
  openx: openxConfig, //FD#12015
  semcasting_amobee: semcastingamobeeConfig,
  semcasting_adobe: semcastingadobeConfig,
  semcasting_tradedesk: semcastingttdConfig,
  semcasting_ttd_third_party: semcastingttd3rdpartyConfig,
  semcasting_xandr: semcastingxandrConfig,
  xandrdv360: xandrdv360Config,
  xandrttd: xandrttdConfig,
};

export const MeasurementTiles = {
  semcastingmeasurement: semcastingmeasurementConfig,
  tvsci: tvsciConfig,
  digitalremedy: digitalremedyConfig,
  grabitmedia: grabitmediaConfig,
  semcasting: semcastingConfig,
  instepmeasurement: instepmeasurementConfig,
};
export const IndirectTiles = {
  amazon: amazonConfig,
  basis: basisConfig,
  liveramp: liverampConfig,
  // FD: 10378
  // stackadapt: stackadaptConfig,
};
export const DirectMailTiles = {
  semcasting_directmail: semcastingdirectmailConfig,
};
export class ActivateFormBase {
  constructor(obj) {
    Object.assign(this, obj);
  }

  instructions =
    "Select the device types or identifiers you’d like to deploy to your seat on your destination " +
    "demand-side platform. You will need to provide your campaign start and end date and your planned CPM and " +
    "rate type. You will also need to input required account information for each platform. This " +
    "information may differ for each platform. Information may include account name, account number or " +
    "ID, account or seat identification code, or encryption or API key. All fields must be completed in " +
    "order to push an audience to your seat. Please add your Account Manager information if applicable.";
  companyText = null;
  company = null;
  companyBlockOptions = null;
  loginLink = null;
  layout = null;
  savableDataNames = null;

  // extra fields required
  aqData = null;
  activationStatus = null;
  pricingData = null;
  defaultData = null;
  segment = null;
  savableFieldsPreFillData = {};

  // extras for helper functions
  emailPlaceHolder = "<%=email%>";
  subjectPlaceHolder = "<%=subject%>";
  bodyPlaceHolder = "<%=body%>";
  templates = {
    mailtoLink:
      '<a href="mailto:' +
      this.emailPlaceHolder +
      "?subject=" +
      this.subjectPlaceHolder +
      "&body=" +
      this.bodyPlaceHolder +
      '" class="text-bold" target="_blank">' +
      this.emailPlaceHolder +
      "</a>",
  };

  //other model class variables for UI updated/rerender
  disabled: false;
  alert: null;
  showAlert: null;
  //Enable editable fields
  updateEndDateDisabled: false;
  updateCreativeTagDisabled: false;

  //ACTIVATION FORM PAYLOAD HELPERS
  initialize(formik, options) {
    //console.log("inside initialize")
    //old
    this.segment = options?.segmentData || {};
    this.pricingData = options?.pricingData || {};
    this.aqData = options?.aqData || {};

    this.defaultData = options?.defaultData || {};
    this.defaultData = {
      ...this.defaultData,
      ...this.appendDefaultPayload(formik),
    };

    //put segment_name on the form data so it will prepopulate the segment name field
    this.aqData.segment_name = this.segment.segment_name || "";
    // console.log("ActivateFormBase.defaultData=", this.defaultData);
    // console.log("ActivateFormBase.pricingData=", this.pricingData);
    // console.log("ActivateFormBase.aqData=", this.aqData);

    //set the forms data on the model
    const formData = this.formatFormData({
      ...this.defaultData,
      ...this.defaultData,
      ...this.pricingData,
      ...this.aqData,
    });

    this.activationStatus = formData.status;

    this.model = new ActivateModal({});
    this.model.initialize(formData, {
      savableDataNames: this.savableDataNames,
    });

    this.addFormDecorations();
  }

  appendDefaultPayload(formik) {
    return {
      semcasting_numeric_segment_id: this.segment?.id,
      company_name: this.company,
      save_credentials: formik?.values?.save_credentials || false,
      segment_name: this.segment?.segment_name,
    };
  }

  addFormDecorations() {
    const self = this;
    if (this.isActivated()) {
      self.disabled = true;
      self.updateEndDateDisabled = true;
      self.updateCreativeTagDisabled = true;
    } else if (this.isRunning()) {
      self.disabled = true;
      self.updateEndDateDisabled = true;
      self.updateCreativeTagDisabled = true;
    } else if (this.isError()) {
      this._showActivationAlert(
        this.model?.initialAttributes?.created_at,
        this.model?.initialAttributes?.status_message || ""
      );
    }
  }

  async fetchSavableFieldsData() {
    if (this.savableDataNames?.length) {
      let promises = this.savableDataNames?.map((fieldName) => {
        return fetchSavableFieldsPreFillData(this.company, fieldName);
      });
      try {
        const promiseValues = await Promise.allSettled(promises);
        if (promiseValues?.length) {
          let savableFieldsPreFillData = {};
          promiseValues.forEach((eachFieldResp) => {
            try {
              if (eachFieldResp?.value?.length > 0) {
                savableFieldsPreFillData[
                  eachFieldResp?.value[0]?.property_name
                ] = eachFieldResp?.value;
              }
            } catch (e) {}
          });
          this.savableFieldsPreFillData = savableFieldsPreFillData;
        }
      } catch (e) {}
    }
  }

  isActivated() {
    return this.activationStatus === "COMPLETED";
  }

  isRunning() {
    return (
      this.activationStatus === "QUEUED" || this.activationStatus === "RUNNING"
    );
  }

  isError() {
    return this.activationStatus === "ERROR";
  }

  getStatusMessage() {
    if (this.isActivated()) {
      return "Activated";
    } else if (this.isRunning()) {
      return "Running";
    } else if (this.isError()) {
      return "Activation Error";
    }
  }

  _showActivationAlert(createdAt, statusMessageJsonString) {
    const statusMessage =
      UDX.utils.parseJsonNoErrors(statusMessageJsonString) || {};
    const publicErrorMsg = statusMessage?.publicMessage || "";
    let alertMsg;
    if (!publicErrorMsg || publicErrorMsg.indexOf("contact") > -1) {
      //assumes error message says Error, please contact support
      alertMsg =
        "Error, please contact " +
        this._createErrorMailtoLink(
          this.segment.id,
          createdAt,
          this.company,
          publicErrorMsg
        );
    } else {
      alertMsg = publicErrorMsg;
      alertMsg +=
        " " +
        this._createErrorMailtoLink(
          this.segment.id,
          createdAt,
          this.company,
          publicErrorMsg
        );
    }

    const alert = {
      type: "danger",
      hovering: false,
      msg: alertMsg,
      hideCloseButton: true,
    };
    this.createAlert(alert);
    this.showAlert = true;
  }

  createAlert(alert) {
    this.alert = alert;
  }

  _createErrorMailtoLink(segmentId, createdAt, companyName, errorMessage) {
    const email = UDX?.customerServiceEmail;
    const subject =
      "Error Activating Segment ID: " +
      segmentId +
      " with company: " +
      companyName;
    const body =
      "Error Date: " +
      moment(createdAt).format(UDX?.shortDateTimeFormat) +
      "\r\n\r\n" +
      "Activating Segment ID: " +
      segmentId +
      " with company: " +
      companyName +
      " has encountered the following error: " +
      errorMessage +
      "\r\n\r\n";

    const templateObj = {
      email: email,
      subject: encodeURIComponent(subject),
      body: encodeURIComponent(body),
    };

    return this.templates.mailtoLink
      .replaceAll(this.emailPlaceHolder, templateObj.email)
      .replace(this.subjectPlaceHolder, templateObj.subject)
      .replace(this.bodyPlaceHolder, templateObj.body);
  }

  formatFormData(formData) {
    console.log("ActivateSegmentHelperFormData=", formData);
    formData = formData || {};
    formData = {
      ...formData,
      ...{
        semcasting_numeric_segment_id: this.segment?.id,
        company_name: this.company,
      },
    };

    if (formData.hasOwnProperty("price")) {
      formData.price = priceFormat(this.pricingData?.price);
    }

    if (formData.hasOwnProperty("pricing_structure")) {
      //formData.pricing_structure = this.pricingData?.pricing_structure !== "CPM" ? this.pricingData?.pricing_structure.toLowerCase().capitalize() : this.pricingData?.pricing_structure;
      //TODO VENKAT check capitalize functionality
      formData.pricing_structure =
        this.pricingData?.pricing_structure !== "CPM"
          ? this.pricingData?.pricing_structure
          : this.pricingData?.pricing_structure;
    }

    // if (
    //   formData.hasOwnProperty("device_type") &&
    //   Array.isArray(formData.device_type)
    // ) {
    //   const indexOfIosAndroid = formData.device_type.indexOf("ios_android");
    //   const indexOfTopIosAndroid =
    //     formData.device_type.indexOf("top_ios_android");
    //
    //   if (indexOfIosAndroid > -1) {
    //     formData.mobile_devices = formData.device_type.splice(
    //       indexOfIosAndroid,
    //       1
    //     )[0];
    //   } else if (indexOfTopIosAndroid > -1) {
    //     formData.mobile_devices = formData.device_type.splice(
    //       indexOfTopIosAndroid,
    //       1
    //     )[0];
    //   }
    //   console.log("formData.mobile_devices=",  formData.mobile_devices);
    // }

    formData = this._mergeCredentialsDataWithFormData(formData);

    return formData;
  }

  _mergeCredentialsDataWithFormData(formData) {
    if (formData?.credentials_data?.length) {
      formData.credentials_data.forEach(function (obj) {
        formData[obj.property_name] = obj.value;
      });
    }
    return formData;
  }

  async resetForm(formik) {
    const defaultAttributes = { ...this.defaultData, ...this.pricingData };
    await formik.resetForm();
    this.model.initialAttributes = defaultAttributes;
    /*this.model.reset(defaultAttributes);

        UDX.UDXForm.prototype.resetForm.apply(this)

        //resetting the form will not trigger a change event on any fields
        //so the model will not be updated and as a result the form layout will not be updated
        //to fix this we set the model data manually
        var obj = this.serializeFormNoFiles(this.$el);
        this.model.set(obj)*/
    // this.model.trigger('sync');
    // this.resetDirty();
  }

  async saveIfValid(formik) {
    try {
      // console.log("saveIfValidFormik=", formik);
      if (formik.isValid && this.model) {
        const serializeFormProps = this.serializeFormNoFiles(formik);
        this.model.initialAttributes = {
          ...this.model.initialAttributes,
          ...serializeFormProps,
        };
        this.model.syncable = [
          ...this.model.syncableBase,
          ...Object.keys(serializeFormProps),
        ];
        let finalPayload = {};
        this.model.syncable.forEach((field) => {
          finalPayload[field] = this.model.initialAttributes[field];
        });
        const url = this.model.urlRoot();
        this.model.syncable = [];
        // console.log("ActivateSegmentHelpersfinalPayload=", finalPayload);
        let response = await semcastAxios.post(url, finalPayload, {
          withCredentials: true,
        });
        return response;
      } else {
        return null;
      }
    } catch (e) {
      console.log("e", e);
      return e?.response;
    }
  }

  isSavingCredentials(formik) {
    return formik?.values?.save_credentials;
  }

  serializeFormNoFiles(formik) {
    const obj = { ...formik.values };
    try {
      const credentialsData = this.createSavableData(formik);
      if (credentialsData?.length) {
        obj.credentials_data = credentialsData;
      }

      if (obj.mobile_devices) {
        obj.device_type = obj.device_type || [];
        obj.device_type.push(obj.mobile_devices);
        // delete obj.mobile_devices;
      } else if (this.model?.initialAttributes?.mobile_devices) {
        obj.device_type = obj.device_type || [];
        obj.device_type.push(this.model?.initialAttributes?.mobile_devices);
        // delete obj.mobile_devices;
      }
    } catch (e) {
      console.log("serializeFormNoFiles():: Exception occurred ::" + e);
    }

    return obj;
  }

  createSavableData(formik) {
    const data = [];
    try {
      if (this.savableDataNames?.length) {
        for (let i = 0; i < this.savableDataNames.length; i++) {
          const propertyName = this.savableDataNames[i];
          //TODO VENKAT check below code
          /*var value = $select.dropdown('get text');
                     var id = $select.dropdown('get value');*/
          const value = formik?.values[propertyName];
          let id = formik?.values[propertyName];
          id = id === value ? 0 : id;
          id = id && !isNaN(id) ? parseInt(id) : id;

          if (value && (id || id === 0)) {
            data.push({
              id: id,
              company_name: this.company,
              property_name: propertyName,
              value: value,
            });
          }
        }
      }
    } catch (e) {
      console.log("createSavableData():: Exception occurred ::" + e);
    }

    return data;
  }
}

export class ActivateView {
  pricingIndex = {};
  activatedFormData = {};
  companyTileColorTextData = {};
  segment = {};
  hiddenCompaniesArray = [];

  initialize() {
    //if UDX.Attributor.ActivateFormSemcasting is present everything else in this block should be present too
    // TODO VENKAT
    /* if(UDX.Attributor.ActivateFormSemcastingAdobe){
               this.formTypes.semcasting_adobe = UDX.Attributor.ActivateFormSemcastingAdobe;
               this.formTypes.semcasting_amobee = UDX.Attributor.ActivateFormSemcastingAmobee;
               this.formTypes.semcasting_tradedesk = UDX.Attributor.ActivateFormSemcastingTradedesk;
               // BKR 2021-04-05 Added Semcasting TTD third-party tile.
               this.formTypes.semcasting_ttd_third_party = UDX.Attributor.ActivateFormSemcastingTtdThirdParty;
               this.formTypes.semcasting_xandr = UDX.Attributor.ActivateFormSemcastingXandr;
          }*/
  }

  segmentationUploadedRecords(segment) {
    // console.log ("segmentationUploadedRecordsSegment=", segment);
    // const billingRate = segment["billing_rate"];

    // Format the billingRate as currency
    // const formattedBillingRate = billingRate?.toLocaleString('en-US', {
    //   style: 'currency',
    //   currency: 'USD',
    //   minimumFractionDigits: 2,
    // });
    // console.log("formattedBillingRate=", formattedBillingRate);
    // Matched
    // Duplicates
    // Rejected. (from the bottom)
    // Percent
    // Emails  (what else would it be other than unique?)
    // Network IPs. (these are unique IPs)
    // WIFI IPs. (these are unique IPs)
    // All Devices
    // Top Devices
    // File Matched (remove - it is redundant with Matched)
    //CPM  (Label should be CPM - Still showing $0.75 rather than $1.00)”
    return [

      {
        text: "Household Match", //FD#11549
        size: segment["total_households"] || 0,
      },
      {
        text: "Matched",
        size: segment["total_businesses"] || 0,
      },
      {
        text: "Duplicates",
        size: segment["duplicates_ct"] || 0,
      },
      {
        //the comment from John:
        // Rejected is a term for those records that copy into table columns would not perform due to format, truncation, ... and
        // not an application rejection.That type of rejection is to be considered a non-match which we will be addressing soon by
        // an unload of those rows to SFTP for the user and the non-match count will be the total rows of that file.
        text: "Rejected",
        size: 0,
      },
      // {
      //   text: "Rejected",
      //   size: segment["total_people"]  || 0,
      // },
      {
        text: "Percent",
        size: segment["pct_served"] || 0,
      },
      {
        text: "Emails",
        size: segment["total_unique_emails"] || 0,
      },

      // {
      //   text: "Unique Phones",
      //   size: segment["total_phones"] || 0,
      // },
      {
        text: "Network IPs",
        size: segment["total_household_network_ip_addresses"] || 0,
      },
      {
        text: "Network IPs",  //business
        size: segment["total_business_network_ip_addresses"] || 0,
      },
      {
        text: "Wifi IPs",
        size: segment["total_household_wifi_ip_addresses"] || 0,
      },
      {
        text: "Wifi IPs",  //business
        size: segment["total_business_wifi_ip_addresses"] || 0,
      },
      // FD: 10336
      // {
      //   text: "All Devices",
      //   size: segment["total_household_devices"] || 0,
      // },
      // {
      //   text: "All Devices", //business
      //   size: segment["total_business_devices"] || 0,
      // },
      {
        text: "Top Devices",
        size: segment["total_top_household_devices"] || 0,
      },
      {
        text: "Top Devices", //business
        size: segment["total_top_business_devices"] || 0,
      },
      {
        text: "Not Matched",
        size: segment["unmatched_ct"] || 0,
      },
      {
        text: "CPM",
        size: segment["billing_rate"] || 0,
        // size: "1.00"
      },
    ];
  }

  markCompanyBlocks() {
    for (let companyName in this.activatedFormData) {
      const formData = this.activatedFormData[companyName].aqData || {};
      let markerClass = "";
      let buttonClass = "btn-primary";
      let buttonText = "Activate";
      let faIconClass = null;
      let faIconColorClass = null;
      if (formData.status === "ERROR") {
        markerClass = "is-error";
        buttonClass = "btn-danger";
        buttonText = "Fix Error";
        faIconClass = "fa-exclamation-circle";
        faIconColorClass = "status-icon-error";
      } else if (formData.status === "COMPLETED") {
        markerClass = "is-activated";
        buttonClass = "btn-light";
        buttonText = "Activated";
        faIconClass = "fa-check-circle";
        faIconColorClass = "status-icon-success";
      } else if (["QUEUED", "RUNNING"].indexOf(formData.status) > -1) {
        markerClass = "is-running";
        buttonClass = "btn-light";
        buttonText = "Running";
        faIconClass = "fa-refresh";
        faIconColorClass = "status-icon-progress";
      }
      this.companyTileColorTextData[companyName] = {
        markerClass,
        buttonClass,
        buttonText,
        faIconClass,
        faIconColorClass,
      };
    }
  }

  isActivated(company) {
    return this.activatedFormData[company]?.status === "COMPLETED";
  }

  isRunning(company) {
    return (
        this.activatedFormData[company]?.status === "QUEUED" ||
        this.activatedFormData[company]?.status === "RUNNING"
    );
  }

  isError(company) {
    return this.activatedFormData[company]?.status === "ERROR";
  }

  async fetchSegment(id) {
    const segment = await loadUploadedRecordsData(id);
    this.segment = segment;
    return segment;
  }

  // async fetchAccountPermissions() {
  //   const self = this;
  //   const permissions = await accountPermissions();
  //   console.log("fetchAccountPermissions=", permissions);
  //   const hiddenCompaniesPermissionObj = self._getPermissionFromPermissionArray(
  //     permissions,
  //     HIDDEN_ONBOARDING_COMPANIES
  //   );
  //   // console.log("hiddenCompaniesPermissionObj=", hiddenCompaniesPermissionObj);
  //   if (hiddenCompaniesPermissionObj?.permission_value?.length) {
  //     self.hiddenCompaniesArray = hiddenCompaniesPermissionObj.permission_value;
  //   }
  // }

  async fetchAccountPermissions() {
    const self = this;
    const permissions = await accountPermissions();
    console.log("fetchAccountPermissions=", permissions);
    // const hiddenCompaniesPermissionObj = self._getPermissionFromPermissionArray(
    //     permissions,
    //     [HIDDEN_ONBOARDING_COMPANIES, SEMCASTING_TILES]
    // );
    const hiddenCompaniesPermissionObj = self._getPermissionFromPermissionArray(
        permissions,
        HIDDEN_ONBOARDING_COMPANIES
    );

    console.log("hiddenCompaniesPermissionObj=", hiddenCompaniesPermissionObj);
    console.log("hiddenCompaniesPermissionObj.permission_value=", hiddenCompaniesPermissionObj?.permission_value);
    if (hiddenCompaniesPermissionObj?.permission_value?.length) {
      self.hiddenCompaniesArray = hiddenCompaniesPermissionObj.permission_value;
      console.log("hiddenCompaniesArray1=", self.hiddenCompaniesArray);
    }
    let user = JSON.parse(sessionStorage.getItem(USER));
    let isSemcastingUser = user && user.email && user.email.includes("semcasting");
    console.log("isSemcastingUser=", isSemcastingUser);
    if (!isSemcastingUser) {
      const semcastingCompaniesPermissionObj = self._getPermissionFromPermissionArray(
          permissions,
          SEMCASTING_TILES
      );
      console.log("semcastingCompaniesPermissionObj=", semcastingCompaniesPermissionObj);
      console.log("semcastingCompaniesPermissionObj.permission_value=", semcastingCompaniesPermissionObj?.permission_value);
      if (semcastingCompaniesPermissionObj?.permission_value?.length) {
        console.log("BeforeHiddenCompaniesArray=", self.hiddenCompaniesArray);
        self.hiddenCompaniesArray = self.hiddenCompaniesArray.concat(semcastingCompaniesPermissionObj.permission_value);
        self.hiddenCompaniesArray = [...new Set(self.hiddenCompaniesArray)];
        console.log("hiddenCompaniesArray2=", self.hiddenCompaniesArray);
      }
    }
  }


  async fetchAccountPricing(segmentId) {
    const self = this;
    const accountPricingList = (await accountPricing(segmentId)) || [];
    accountPricingList.forEach(function (item) {
      self.pricingIndex[item.company] = {
        price: item.price,
        pricing_structure: item.pricing_structure,
      };
    });
    self._addPricingDataFromList(self.pricingIndex);
  }

  async fetchAlreadyActivatedData(segmentId) {
    // console.log("ActivateSegmentHelpersFetchAlreadyActivatedData");
    const self = this;
    const alreadyActivatedData =
        (await fetchAlreadyActivatedData(segmentId)) || [];
    if (alreadyActivatedData?.length) {
      const alreadyActivatedDataList =
          self._parseActivationData(alreadyActivatedData);
      self._addActivatedFormDataFromList(alreadyActivatedDataList);
    }
    return alreadyActivatedData;
  }

  _addPricingDataFromList(pricingIndex) {
    for (let companyName in pricingIndex) {
      if (!this.activatedFormData[companyName]) {
        this.activatedFormData[companyName] = {};
      }

      this.activatedFormData[companyName].pricingData = Object.assign(
          {},
          pricingIndex[companyName]
      );
    }
  }

  _addActivatedFormDataFromList(listOfActivationDataByCompany) {
    const self = this;
    listOfActivationDataByCompany.forEach((activationQueueData) => {
      if (!self.activatedFormData[activationQueueData.company_name]) {
        self.activatedFormData[activationQueueData.company_name] = {};
      }

      self.activatedFormData[activationQueueData.company_name].aqData =
          JSON.parse(JSON.stringify(activationQueueData));
    });
  }

  _parseActivationData(activationDataList) {
    return activationDataList.map((item) => {
      try {
        item.data = JSON.parse(item.data);
        item = {...item, ...item.data};
      } catch (e) {
      } finally {
        return item;
      }
    });
  }

  _getPermissionFromPermissionArray(permissions, permissionName) {

    if (permissions && permissions.length) {
      return permissions.find(function (obj) {
        return obj.permission_name === permissionName;
      });
    } else {
      console.log("permissions is required and must be an array");
    }
  }

// _getPermissionFromPermissionArray(permissions, permissionNames) {
//     if (!permissions || !Array.isArray(permissions)) {
//       console.log("permissions is required and must be an array");
//       return null;
//     }
//
//     if (!Array.isArray(permissionNames)) {
//       console.log("permissionNames must be an array");
//       return null;
//     }
//
//     let user = JSON.parse(sessionStorage.getItem(USER));
//     let isSemcastingUser = user && user.email && user.email.includes("semcasting");
//
//     let permissionValues = [];
//     let permissionObj = null;
//
//     permissions.forEach(function (obj) {
//       if (isSemcastingUser && obj.permission_name === "semcastingTiles") {
//         return;
//       }
//       if (permissionNames.includes(obj.permission_name)) {
//         permissionValues.push(obj.permission_name);
//         if (!permissionObj) {
//           permissionObj = {
//             founding_user_id: obj.founding_user_id || 0,
//             id: obj.id || 0,
//             permission_name: obj.permission_name,
//             permission_value: []
//           };
//         }
//       }
//     });
//
//     if (permissionObj) {
//       permissionObj.permission_value = permissionValues;
//     }
//
//     return permissionObj;
//   }
}


export class ActivateModal {
  constructor(obj) {
    Object.assign(this, obj);
  }

  //from UDXMOdel
  initialAttributes = {};
  options = {};
  syncable = [];

  syncableBase = [
    "id",
    "price",
    "pricing_structure",
    "segment_name",
    "semcasting_numeric_segment_id",
    "company_name",
    "save_credentials",
    "credentials_data",
    "device_type",
  ];

  urlRoot() {
    return (
      ACTIVATE_SEGMENT.replace(
        COMPANY_ID_PLACE_HOLDER,
        this.initialAttributes?.company_name
      ) + this.initialAttributes?.semcasting_numeric_segment_id
    );
  }

  /**
   * names of properties to treat as credentials data
   * {Array}
   */
  savableDataNames = null;

  /**
   * is new must always return true so that the POST method will be used
   * when saving
   * @returns {boolean} true
   */
  isNew() {
    return true;
  }

  initialize(attributes, options) {
    options = options || {};
    attributes = attributes || {};
    this.initialAttributes = attributes;
    this.options = options;
    if (!attributes.company_name) {
      const msg =
        "You must pass a company_name as an attributes parameter to UDX.Attributor.ActivateModel";
      console.error(msg);
      throw new Error(msg);
    }

    this.savableDataNames = options.savableDataNames || [];
  }

  /*
        toJSON(options) {
            options = options || {};
            const self = this;
            const attributes = options.attrs;
            const clonedAttributes = JSON.parse(JSON.stringify(attributes));

            //remove credential properties from the main object because they are also in the credentials_data object
            if (attributes?.credentials_data?.length) {
                attributes.credentials_data.map(function (obj, index) {
                    delete clonedAttributes[obj.property_name];
                });
            }

            //remove segments data properties from the main object because they are also in the segments_data object
            if (attributes?.segments_data?.length) {
                attributes.segments_data.map(function (obj, index) {
                    for (const propName in obj) {
                        const deletableName = obj.semSegmentId ? propName + self.nameIdDivider + obj.semSegmentId : propName;
                        delete clonedAttributes[deletableName];
                    }
                });
            }

            return clonedAttributes;
        }

        save(key, val, options) {
            let attrs;
            if (key == null) {
                attrs = this.toJSON()
            } else if (typeof key === 'object') {
                attrs = key;
                options = val;
            } else {
                (attrs = {})[key] = val;
            }
            options = options || {};

            //TODO VENKAT need to write Save API call
            //return UDX.UDXModel.prototype.save.call(this, attrs, options);
        }*/
}
