import React, {useEffect} from "react";
import "./ResourcesPage.css";


const ResourcesPage = (loggedIn) => {


    useEffect(() => {

    });

    return (
        <div className={"resources-page-container"}>
            <div className={"content-wrapper"}>
                <div className={"section-header"}>
                    Overview
                </div>
                <legend className="k-form-legend spacingLine"></legend>
                <div className={"video-1"}>
                    <iframe allowTransparency="true" title="Wistia video player" allowFullScreen frameBorder="0"
                            scrolling="no"
                            className="wistia_embed" name="wistia_embed"
                            src="https://semcasting.wistia.com/medias/g44dqo3bur"
                    ></iframe>
                </div>
            </div>
            <div className={"content-wrapper"}>
                <div className={"section-header"}>
                    Demo
                </div>
                <legend className="k-form-legend spacingLine"></legend>
                <div className={"video-2"}>
                    <iframe allowTransparency="true" title="Wistia video player" allowFullScreen frameBorder="0"
                            scrolling="no"
                            className="wistia_embed" name="wistia_embed"
                            src="https://semcasting.wistia.com/medias/kxjmoyvzx1"
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default ResourcesPage;