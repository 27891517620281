import {Field, Form, FormElement} from "@progress/kendo-react-form";
import {Button} from "@progress/kendo-react-buttons";
import ReCAPTCHA from "react-google-recaptcha";
import {Dialog} from "@progress/kendo-react-dialogs";
import React, {useEffect, useState} from "react";
import "./SignInDialog.css";
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade, Slide } from '@progress/kendo-react-animation';
import { Input } from '@progress/kendo-react-inputs';

// import "./sidCustomMaterial.css";

export const SignInDialog = ({ handleForgotPassword, logo, clickNavSignIn, failedLogin, setFailedLogin, password,
                          showResetPassword, handlePasswordResetWindowChange, handleSubmit, loadCap, disableSubmit,
                         disableInput, SITE_KEY, email, setEmail, setPassword, reCaptchaRef, dialogHeight, handleChange,
                         failedPwReset, setFailedPwReset, successPwReset, setSuccessPwReset, noOfVerifications,
                         notification, setLoadCap, setDisableInput, setDialogHeight, setDisableSubmit}) => {

    const dialogWidth = 420;
    const emailRegex = new RegExp(/\S+@\S+\.\S+/);
    const emailValidator = value => !value ? "Email is required." : emailRegex.test(value) ? "" : "Email is not in a valid format.";
    const passwordValidator = value => !value ? "Password is required" : value.length < 1 ? "Password is required." : "";
    const [showPassword, setShowPassword] = useState(false);

    const showPass = () => setShowPassword(!showPassword);

    useEffect(() => {
        console.log("value of loadCap: ", loadCap);

        // if(noOfVerifications == 3) {
        //     setLoadCap(true);
        //     setDisableInput(true);
        //     setDialogHeight(430);
        //     setDisableSubmit(true);
        // }

        // if(failedPwReset) {
        //     setTimeout(() => {
        //         setFailedPwReset(false)
        //     }, 3000)
        // }
        // if(failedLogin) {
        //     setTimeout(() => {
        //         setFailedLogin(false)
        //     }, 3000)
        // }
        // if(successPwReset) {
        //     setTimeout(() => {
        //         setSuccessPwReset(false)
        //     }, 3000)
        // }

    }, [failedLogin, failedPwReset, successPwReset]);

    return (
        <>
            <Dialog className={"signInDialog"} height={dialogHeight} title={<img src={logo} alt="Audience Designer" style={{margin: "0 auto"}} />}
                    width={dialogWidth} closeIcon={true} onClose={clickNavSignIn}>

                {showResetPassword === true ? (
                    <Form onSubmit={handleForgotPassword} className={"resetPwForm"} render={formRenderProps => <FormElement style={{
                        maxWidth: 650
                    }}>

                        <NotificationGroup style={{
                            right: 0,
                            top: 0,
                            alignItems: 'flex-end',
                        }}>
                            <Slide direction={failedPwReset ? 'up' : 'down'}>
                                {failedPwReset && <Notification type={{
                                    style: 'error',
                                    icon: true
                                }} closable={true} onClose={() => setFailedPwReset(false)}>
                                    <span>Error occurred: Reset email not sent.</span>
                                </Notification>}
                            </Slide>
                            <Slide direction={successPwReset ? 'up' : 'down'}>
                                {successPwReset && <Notification type={{
                                    style: 'success',
                                    icon: true
                                }} closable={true} onClose={() => setSuccessPwReset(false)}>
                                    <span>An email has been sent with further instructions.</span>
                                </Notification>}
                            </Slide>
                        </NotificationGroup>

                        <div className={"signInDialogResetPwDiv signInDialogFormFieldsDiv"}>

                            <Field
                                name={'email'}
                                className={"sidField"}
                                id={'emailPwReset'}
                                type={'email'}
                                component={Input}
                                label={'Email'}
                                validator={emailValidator}
                                onChange={(e) => setEmail(e.target.value)}
                                // onChange={(e) => setEmail(e.target.value)}
                            />

                        <a id="myLink" className="resetPwLink" href="#" onClick={handlePasswordResetWindowChange}>Remembered Password?</a>

                        </div>

                        <div className="k-form-buttons signInDialogButtonDiv">

                            <Button themeColor={'primary'} className={"signInDialogButton"} type={'submit'} disabled={!formRenderProps.allowSubmit || !email.length || emailValidator(email)} >
                                Send Password Reset Email
                            </Button>

                        </div>
                    </FormElement>} />
                ) : (
                    <Form onSubmit={handleSubmit} className={"signInForm"} render={formRenderProps => <FormElement style={{
                        maxWidth: 650
                    }}>

                        <NotificationGroup style={{
                            right: 0,
                            top: 0,
                            alignItems: 'flex-end',
                        }}>
                            <Slide direction={failedLogin ? 'up' : 'down'}>
                                {failedLogin && <Notification type={{
                                    style: 'error',
                                    icon: true
                                }} closable={true} onClose={() => setFailedLogin(false)}>
                                    <span>Login Failed: Incorrect email or password.</span>
                                </Notification>}
                            </Slide>
                        </NotificationGroup>

                        <div className={"signInDialogFormFieldsDiv"}>

                            <Field
                                name={'email'}
                                className={"sidField"}
                                id={'emailSignIn'}
                                type={'email'}
                                component={Input}
                                label={'Email'}
                                validator={emailValidator}
                                onChange={(e) => setEmail(e.target.value)}
                                // onChange={(e) => setEmail(e.target.value)}
                            />

                            <div className={"signInPasswordDiv"}>
                                <Field
                                    name={'password'}
                                    className={"sidField passwordSidField"}
                                    component={Input}
                                    label={'Password'}
                                    type={showPassword ? "text" : "password"}
                                    validator={passwordValidator}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <Button type={"button"} className="buttons-container-button showHidePassword" icon="eye"
                                        onClick={showPass} disabled={!password.length}></Button>
                            </div>
                        <a id="myLink" className="resetPwLink" href="#" onClick={handlePasswordResetWindowChange}>Forgot Password?</a>

                        </div>

                        <div className="captcha-container">
                            {loadCap && (
                                <ReCAPTCHA
                                    className={"g-recaptcha"}
                                    ref={reCaptchaRef}
                                    sitekey={SITE_KEY}
                                    onChange={handleChange}
                                />
                            )}
                        </div>

                        <div className="k-form-buttons signInDialogButtonDiv">

                            <Button themeColor={'primary'} className={"signInDialogButton"} type={'submit'} disabled={!formRenderProps.allowSubmit || disableSubmit || !email.length || !password.length || emailValidator(email)}>
                                Sign In
                            </Button>
                        </div>
                    </FormElement>} />
                )}
            </Dialog>
        </>
    )
};
