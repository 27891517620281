/*************** Highcharts Themes ****************/
    // Define multiple theme options
export const consumerThemeList = [
      {
        id: 1,
        name: "Default",
      },
      {
        id: 2,
        name: "Classic",
      },
      {
        id: 3,
        name: "ClassicLight",
      },
      {
        id: 4,
        name: "ClassicMedium",
      },
      {
        id: 5,
        name: "ClassicPurple",
      },
    ];
export const businessThemeList = [
  {
    id: 1,
    name: "Classic",
  },
  {
    id: 2,
    name: "ClassicMedium",
  },
  {
    id: 3,
    name: "ClassicPale",
  },
  {
    id: 4,
    name: "ClassicLight",
  }
];
// export const themeList = ["Default", "Classic", "ClassicMedium"];
export const highchartConsumerTheme1 = {
      theme1: {
        plotOptions: {
          column: {}, //For Highcharts column/bar chart with more than one series, you need to use the series attribute below.
          bar: {
            colors: [ "#2caffe", "#544fc5", "#00e272", "#fe6a35", "#6b8abc", "#d568fb", "#2ee0ca", "#fa4b42", "#feb56a", "#91e8e1" ]
          },
          pie: {
            colors: [ "#2caffe", "#544fc5", "#00e272", "#fe6a35", "#6b8abc", "#d568fb", "#2ee0ca", "#fa4b42", "#feb56a", "#91e8e1" ],
          },
        },
        //Below is how you define colors for charts with 2 series
        series: [
            {color: '#2caffe'},
          {color: '#544fc5'},
        ]
      },
      theme2: {
        plotOptions: {
          column: {},//For Highcharts column/bar chart with more than one series, you need to use the series attribute below.
          bar: {
            colors: ["#E14D2A","#3E6D9C", "#EF6925", "#6D8EC5", "#FD841F", "#9CAFED", "#9E795E"] //This is when you want different color for each category
          },
          pie: {
            colors: ["#E14D2A","#3E6D9C", "#EF6925", "#6D8EC5", "#FD841F", "#9CAFED", "#9E795E"],
            // dataLabels: {
            //   connectorColor: ["#E14D2A","#3E6D9C", "#EF6925", "#6D8EC5", "#FD841F", "#9CAFED", "#9E795E"] // Set multiple connector colors
            // }
          },
        },
        //Below is how you define colors for charts with 2 series
        series: [
          {color: '#E14D2A'},
          {color: '#3E6D9C'},
          // {data:[
          //     {connectorColor:"#E14D2A" },
          //     {connectorColor:"#3E6D9C" },
          //     {connectorColor:"#EF6925" },
          //     {connectorColor:"#6D8EC5" },
          //     {connectorColor:"#FD841F" },
          //     {connectorColor:"#9CAFED" },
          //     {connectorColor:"#9E795E" },
          //   ]}
        ]
      },
      theme3: {
        plotOptions: {
          column: {
          },
          bar: {
            colors: ["#729ece", "#ff9e4a", "#67bf5c", "#ed665d", "#ad8bc9", "#a8786e", "#ed97ca", "#a2a2a2", "#cdcc5d", "#6dccda"]
          },
          pie: {
            colors: ["#729ece", "#ff9e4a", "#67bf5c", "#ed665d", "#ad8bc9", "#a8786e", "#ed97ca", "#a2a2a2", "#cdcc5d", "#6dccda"],
            // dataLabels: {
            //   connectorColor: ["#729ece", "#ff9e4a", "#67bf5c", "#ed665d", "#ad8bc9", "#a8786e", "#ed97ca", "#a2a2a2", "#cdcc5d", "#6dccda"] // Set multiple connector colors
            // }
          },
        },
        series: [
          {color: '#729ece'},
          {color: '#ff9e4a'},
        ]
      },
      theme4: {
        plotOptions: {
          column: {
          },
          bar: {
            colors: ['#1F77B4', '#FF7F0E', '#2CA02C', '#D62728', '#9467BD', '#8C564B', '#CFECF9', '#7F7F7F', '#BCBD22', '#17BECF']
          },
          pie: {
            colors: ['#1F77B4', '#FF7F0E', '#2CA02C', '#D62728', '#9467BD', '#8C564B', '#CFECF9', '#7F7F7F', '#BCBD22', '#17BECF']
          },
        },
        series: [
          {color: '#1F77B4'},
          {color: '#FF7F0E'},
        ]
      },
      theme5: {
        plotOptions: {
          column: {
          },
          bar: {
            colors: ['#648fff', '#785ef0', '#dc267f', '#fe6100', '#ffb000']
          },
          pie: {
            colors: ['#648fff', '#785ef0', '#dc267f', '#fe6100', '#ffb000']
          },
        },
        series: [
          {color: '#648fff'},
          {color: '#fe6100'},
        ]
      },
    };

export const highchartBusinessTheme1 = {
      theme1: {
        plotOptions: {
          column: {
            color: '#E14D2A' // Set the color of the columns
          },
          bar: {
            color: '#E14D2A' // Set the color of the bars
          },
        },
      },
      theme2: {
        plotOptions: {
          column: {
            color: '#729ece'
          },
          bar: {
            color: '#729ece'
          }
        },
      },
      theme3: {
        plotOptions: {
          column: {
            color: '#aec7e8'
          },
          bar: {
            color: '#aec7e8'
          }
        },
      },
      theme4: {
        plotOptions: {
          column: {
            color: '#1f77b4'
          },
          bar: {
            color: '#1f77b4'
          }
        },
      },
    };
export const highchartBusinessTheme2 = {
  theme1: {
    plotOptions: {
      column: {
        color: '#3E6D9C' // Set the color of the columns
      },
      bar: {
        color: '#3E6D9C' // Set the color of the bars
      }
    },
  },
  theme2: {
    plotOptions: {
      column: {
        color: '#ff9e4a'
      },
      bar: {
        color: '#ff9e4a'
      }
    },
  },
  theme3: {
    plotOptions: {
      column: {
        color: '#ffbb78'
      },
      bar: {
        color: '#ffbb78'
      }
    },
  },
  theme4: {
    plotOptions: {
      column: {
        color: '#aec7e8'
      },
      bar: {
        color: '#aec7e8'
      }
    },
  },
};
export const highchartBusinessTheme3 = {
  theme1: {
    plotOptions: {
      column: {
        color: '#EF6925' // Set the color of the columns
      },
      bar: {
        color: '#EF6925' // Set the color of the bars
      }
    },
  },
  theme2: {
    plotOptions: {
      column: {
        color: '#67bf5c'
      },
      bar: {
        color: '#67bf5c'
      }
    },
  },
  theme3: {
    plotOptions: {
      column: {
        color: '#98df8a'
      },
      bar: {
        color: '#98df8a'
      }
    },
  },
  theme4: {
    plotOptions: {
      column: {
        color: '#ff7f0e'
      },
      bar: {
        color: '#ff7f0e'
      }
    },
  }
};
export const highchartBusinessTheme4 = {
  theme1: {
    plotOptions: {
      column: {
        color: '#6D8EC5' // Set the color of the columns
      },
      bar: {
        color: '#6D8EC5' // Set the color of the bars
      },
    },
  },
  theme2: {
    plotOptions: {
      column: {
        color: '#ed665d'
      },
      bar: {
        color: '#ed665d'
      }
    },
  },
  theme3: {
    plotOptions: {
      column: {
        color: '#ff9896'
      },
      bar: {
        color: '#ff9896'
      }
    },
  },
  theme4: {
    plotOptions: {
      column: {
        color: '#ffbb78'
      },
      bar: {
        color: '#ffbb78'
      }
    },
  },
};
export const highchartBusinessTheme5 = {
  theme1: {
    plotOptions: {
      pie: {
        colors: ["#E14D2A","#3E6D9C", "#EF6925", "#6D8EC5", "#FD841F", "#9CAFED", "#9E795E"],
      },
    },
  },
  theme2: {
    plotOptions: {
      pie: {
        colors: ["#729ece", "#ff9e4a", "#67bf5c", "#ed665d", "#ad8bc9", "#a8786e", "#ed97ca", "#a2a2a2", "#cdcc5d", "#6dccda"],
      },
    },
  },
  theme3: {
    plotOptions: {
      pie: {
        colors: ["#aec7e8", "#ffbb78", "#98df8a", "#ff9896", "#c5b0d5", "#c49c94", "#f7b6d2", "#c7c7c7", "#dbdb8d", "#9edae5"]
      },
    },
  },
  theme4: {
    plotOptions: {
      pie: {
        colors: ["#1f77b4", "#aec7e8", "#ff7f0e", "#ffbb78", "#2ca02c", "#98df8a", "#d62728", "#ff9896", "#9467bd", "#c5b0d5"],
      },
    },
  },
};

export const ConsumerThemeDictionary = [
  {
    id: 1,
    name: "Classic",
    pieColor: ["#E14D2A","#3E6D9C", "#EF6925", "#6D8EC5", "#FD841F", "#9CAFED", "#9E795E"],
    barColor1: ["#3E6D9C"],
    barColor2: ["#EF6925"],
    transColor1: ["rgb(239, 104, 37,0.6)"],
    transColor2: ["rgb(62, 109, 156,0.6)"],
  },
  {
    id: 2,
    name: "ClassicMedium",
    pieColor: ["#729ece", "#ff9e4a", "#67bf5c", "#ed665d", "#ad8bc9", "#a8786e", "#ed97ca", "#a2a2a2", "#cdcc5d", "#6dccda"],
    barColor1: ["rgb(255, 158, 74)"],
    barColor2: ["rgb(104, 191, 92)"],
    transColor1: ["rgb(255, 158, 74, 0.6)"],
    transColor2: ["rgb(104, 191, 92, 0.6)"],
  },
  {
    id: 3,
    name: "ClassicPale",
    pieColor: ["#aec7e8", "#ffbb78", "#98df8a", "#ff9896", "#c5b0d5", "#c49c94", "#f7b6d2", "#c7c7c7", "#dbdb8d", "#9edae5"],
    barColor1: ["rgb(197, 176, 213)"],
    barColor2: ["rgb(255, 188, 120)"],
    transColor1: ["rgb(197, 176, 213, 0.6)"],
    transColor2: ["rgb(255, 188, 120, 0.6)"],
  },
  {
    id: 4,
    name: "ClassicLight",
    pieColor: ["#1f77b4", "#aec7e8", "#ff7f0e", "#ffbb78", "#2ca02c", "#98df8a", "#d62728", "#ff9896", "#9467bd", "#c5b0d5"],
    barColor1: ["rgb(174, 199, 232)"],
    barColor2: ["rgb(152, 223, 138)"],
    transColor1: ["rgb(174, 199, 232, 0.6)"],
    transColor2: ["rgb(152, 223, 138, 0.6)"],
  },
  {
    id: 5,
    name: "ClassicGreen",
    pieColor: ["#0A7029", "#84A715", "#FEDE00", "#C8DF52", "#DBE8D8","#EAD69B", "#F9C35E"],
    barColor1: ["rgb(133, 167, 21)"],
    barColor2: ["rgb(254, 220, 0)"],
    transColor1: ["rgb(133, 167, 21, 0.6)"],
    transColor2: ["rgb(254, 220, 0, 0.6)"],
  },
]
export const BusinessThemeDictionary = [
  {
    id: 1,
    name: "Classic",
    pieColor: ["#E14D2A","#3E6D9C", "#EF6925", "#6D8EC5", "#FD841F", "#9CAFED", "#9E795E"],
    barColor1: ["#E14D2A"],
    barColor2: ["#3E6D9C"],
    barColor3: ["#EF6925"],
    barColor4: ["#6D8EC5"],
    transColor1: ["rgb(239, 104, 37,0.6)"],
    transColor2: ["rgb(62, 109, 156,0.6)"],
  },
  {
    id: 2,
    name: "ClassicMedium",
    pieColor: ["#729ece", "#ff9e4a", "#67bf5c", "#ed665d", "#ad8bc9", "#a8786e", "#ed97ca", "#a2a2a2", "#cdcc5d", "#6dccda"],
    barColor1: ["#729ece"],
    barColor2: ["#ff9e4a"],
    barColor3: ["#67bf5c"],
    barColor4: ["#ed665d"],
    transColor1: ["rgb(255, 158, 74, 0.6)"],
    transColor2: ["rgb(104, 191, 92, 0.6)"],
  },
  {
    id: 3,
    name: "ClassicPale",
    pieColor: ["#aec7e8", "#ffbb78", "#98df8a", "#ff9896", "#c5b0d5", "#c49c94", "#f7b6d2", "#c7c7c7", "#dbdb8d", "#9edae5"],
    barColor1: ["#aec7e8"],
    barColor2: ["#ffbb78"],
    barColor3: ["#98df8a"],
    barColor4: ["#ff9896"],
    transColor1: ["rgb(197, 176, 213, 0.6)"],
    transColor2: ["rgb(255, 188, 120, 0.6)"],
  },
  {
    id: 4,
    name: "ClassicLight",
    pieColor: ["#1f77b4", "#aec7e8", "#ff7f0e", "#ffbb78", "#2ca02c", "#98df8a", "#d62728", "#ff9896", "#9467bd", "#c5b0d5"],
    barColor1: ["#1f77b4"],
    barColor2: ["#aec7e8"],
    barColor3: ["#ff7f0e"],
    barColor4: ["#ffbb78"],
    transColor1: ["rgb(174, 199, 232, 0.6)"],
    transColor2: ["rgb(152, 223, 138, 0.6)"],
  },
  {
    id: 5,
    name: "ClassicGreen",
    pieColor: ["#0A7029", "#84A715", "#FEDE00", "#C8DF52", "#DBE8D8","#EAD69B", "#F9C35E"],
    barColor1: ["#0A7029"],
    barColor2: ["#84A715"],
    barColor3: ["#FEDE00"],
    barColor4: ["#C8DF52"],
    transColor1: ["rgb(133, 167, 21, 0.6)"],
    transColor2: ["rgb(254, 220, 0, 0.6)"],
  },
]
export const audienceSupplyPathOptData = {
  labels: [],
  datasets: [
    {
      label: "% of Match",
      data: [],
    },
  ],
};
export const locationData = {
  labels: [],
  datasets: [
    {
      label: "# of Votes",
      data: [],
    },
  ],
};

export const ageLabels = [];
export const ageData = {
  labels: ageLabels,
  datasets: [
    {
      label: "National Average",
      data: [],
    },
    {
      label: "Uploaded Records",
      data: [],
    },
  ],
};

export const socialMatrixData = {
  labels: [],
  datasets: [
    {
      label: "National Average",
      data: [],
      // backgroundColor: "rgb(92, 178, 88, 0.3)",
      // borderColor: "rgb(92, 178, 88, 1)",
      // pointHoverBackgroundColor: "rgb(92, 178, 88, 1)",
      pointHoverRadius:8,
      order: 1,
    },
    {
      label: "Uploaded Records",
      data: [],
      // backgroundColor: "rgba(255, 127, 15, 0.3)",
      // borderColor: "rgba(255, 127, 15, 1)",
      // pointHoverBackgroundColor: "rgba(255, 127, 15, 1)",
      pointHoverRadius:8,
      // borderWidth: 1.5,
    },
  ],
};
// export const radarOptions = {
//   scale: {
//     ticks: { beginAtZero: true },
//   },
// };

export const ethnicityData = {
  labels: [],
  datasets: [
    {
      label: "Ethnicity",
      data: [],
    },
  ],
};

export const politicalPartyData = {
  labels: [],
  datasets: [
    {
      label: "# of Votes",
      data: [],
    },
  ],
};

export const incomeRangeData = {
  labels: [],
  datasets: [
    {
      label: "National Average",
      data: [],
      // borderColor: "rgba(255, 127, 15, 1)",
      // backgroundColor: "rgba(255, 127, 15, 1)",
      pointHoverRadius:8,
      type: "line",
      order: 0,
    },
    {
      label: "Uploaded Records",
      data: [],
      // backgroundColor: "rgba(165, 199, 97, 1)",
      // borderColor: "rgba(255,255,255,1)",
      // hoverBackgroundColor:"rgba(165, 199, 97, 1)",
      // borderWidth: 1.5,
    },
  ],
};

export const charitableContributionDecileData = {
  labels: [],
  datasets: [
    {
      label: "National Average",
      data: [],
      // borderColor: "rgba(255, 127, 15, 1)",
      // backgroundColor: "rgba(255, 127, 15, 1)",
      pointHoverRadius:8,
      type: "line",
      order: 0,
    },
    {
      label: "Uploaded Records",
      data: [],
      // backgroundColor: "rgba(119, 215, 230, 1)",
      // borderColor: "rgba(255,255,255,1)",
      // hoverBackgroundColor:"rgba(119, 215, 230, 1)",
      // borderWidth: 1.5,
    },
  ],
};

export const homeValueData = {
  labels: [],
  datasets: [
    {
      label: "National Average",
      data: [],
      // borderColor: "rgba(255, 127, 15, 1)",
      // backgroundColor: "rgba(255, 127, 15, 1)",
      pointHoverRadius:8,
      type: "line",
      order: 0,
    },
    {
      label: "Uploaded Records",
      data: [],
      // backgroundColor: "rgba(220, 157, 81, 1)",
      // borderColor: "rgba(255,255,255,1)",
      // hoverBackgroundColor:"rgba(220, 157, 81, 1)",
      // borderWidth: 1.5,
    },
  ],
};

export const discretionaryLabels = [];
export const discretionaryData = {
  labels: discretionaryLabels,
  datasets: [
    {
      label: "National Average",
      data: [],
      // backgroundColor: "rgba(255, 127, 15, 1)",
      // borderColor: "rgba(255, 127, 15, 1)",
      // hoverBackgroundColor: "rgba(255, 127, 15, 1)",
      // borderWidth:1.5,
    },
    {
      label: "Uploaded Records",
      data: [],
      // backgroundColor: "rgba(165, 199, 97, 1)",
      // borderColor: "rgba(255,255,255,1)",
      // hoverBackgroundColor:"rgba(165, 199, 97, 1)",
      // borderWidth:1.5,
    },
  ],
};

export const netWorthLabels = [];
export const netWorthData = {
  labels: netWorthLabels,
  datasets: [
    {
      label: "National Average",
      data: [],
      // backgroundColor: "rgba(255, 127, 15, 0.3)",
      // borderColor: "rgba(255, 127, 15, 1)",
      // pointHoverBackgroundColor: "rgba(255, 127, 15, 1)",
      pointHoverRadius:8,
      // borderWidth:1.5,
      fill: true,
      order: 1,
    },
    {
      label: "Uploaded Records",
      data: [],
      // backgroundColor: "rgba(54, 162, 235, 0.3)",
      // borderColor: "rgba(54, 162, 235,1)",
      // pointHoverBackgroundColor: "rgba(54, 162, 235, 1)",
      pointHoverRadius:8,
      fill: true,
    }    
  ],
};

export const investedAssetsLabels = [];
export const investedAssetsData = {
  labels: investedAssetsLabels,
  datasets: [
    {
      label: "National Average",
      data: [],
      pointHoverRadius:8,
      type: "line",
    },
    {
      label: "Uploaded Records",
      data: [],
    },
  ],
};

export const gendersPresentData = {
  labels: [],
  datasets: [
    {
      label: "Genders Present",
      data: [],
    },
  ],
};

export const numberOfChildrenData = {
  labels: [],
  datasets: [
    {
      label: "Number of Children",
      data: [],
    },
  ],
};

const actions = [
  {
    name: "Position: top",
    handler(chart) {
      chart.options.plugins.legend.position = "top";
      chart.update();
    },
  },
  {
    name: "Position: right",
    handler(chart) {
      chart.options.plugins.legend.position = "right";
      chart.update();
    },
  },
  {
    name: "Position: bottom",
    handler(chart) {
      chart.options.plugins.legend.position = "bottom";
      chart.update();
    },
  },
  {
    name: "Position: left",
    handler(chart) {
      chart.options.plugins.legend.position = "left";
      chart.update();
    },
  },
];

export const chartOptions = {
  legend: {
    labels: {
      fontSize: window.innerWidth > 350 ? 20 : 10,
    },
  },
};
