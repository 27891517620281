import React, {useState, useEffect, useContext} from "react";
import {Drawer, DrawerContent, DrawerItem} from '@progress/kendo-react-layout';
import {useHistory, withRouter} from 'react-router-dom';
import {CONTACT_US_ROUTE, SIGN_OUT_API} from "../../api/constants";
import {removeDataFromStorage, semcastAxios} from "../../Utils/Common";
import SideNavigationBarHeader from "./SideNavigationBarHeader";
import { Tooltip } from "@progress/kendo-react-tooltip";
// import * as shepherd from "shepherd.js";

import "./SideNavigationBar.css";
import {ShepherdTourContext, useShepherdTour,ShepherdTour} from "react-shepherd";
import {
    adminsettingsTourSteps,
    audiencedesignTourSteps,
    dashboardTourSteps,
    onboardingTourSteps,
    tourOptions
} from "../Pages/GuidedTour"
import Shepherd from "shepherd.js";


const SideNavigationBar = (props) => {
    const [adminExpanded, setAdminExpanded] = useState(false);
    let history = useHistory();

    const dashboardTour = useShepherdTour({ tourOptions, steps: dashboardTourSteps });
    const onboardingTour = useShepherdTour({ tourOptions, steps: onboardingTourSteps });
    const audiencedesignTour = useShepherdTour({ tourOptions, steps: audiencedesignTourSteps });
    const adminsettingsTour = useShepherdTour({ tourOptions, steps: adminsettingsTourSteps });
    let pathname = window.location.pathname;


    const CustomItem = itemProps => {
        const {
            visible,
            ...others
        } = itemProps;
        if (itemProps.id === 4) {
            console.log(itemProps);
            // if (localStorage.getItem("selectedSegmentId"))  {
            //     const selectedId = localStorage.getItem("selectedSegmentId");
            //     localStorage.removeItem("selectedSegmentId");
            // }
        }
        if (itemProps.id === 6) {
            if (itemProps['data-expanded'] === true) {
                alert("YES")
            } else {
                alert("no")
            }
        }
        if (itemProps.id === 5) {
            if (itemProps['data-expanded'] === true) {
                setAdminExpanded(true);
            } else {
                setAdminExpanded(false);
            }
        }
        if (itemProps.id === 11) {
            if (props.drawerExpanded) {
                return <React.Fragment>
                    {props.visible === false ? null : <DrawerItem {...others}>
                        <div className={"contactUsSidebar"}>
                            {/* <div className="contact-us-sidebar-content"> */}
                                {/* <h3>Help Center</h3> */}
                                {/* <p style={{color:"lightgrey"}}>Visit our help center</p> */}
                                {/*<a href={CONTACT_US_ROUTE} className="btn contact-us-button">*/}
                                {/*    Contact*/}
                                {/*</a>*/}
                                {/* <Button type={"button"} className={"btn contact-us-button"} style={{boxShadow: "none"}} */}
                                        {/* onClick={() => history.push("/contact-us")}>Contact Us</Button> */}
                            {/* </div> */}
                            <div className="copyright" style={{textAlign:"center"}}>
                                <p style={{color:"lightgrey"}}>
                                    ©2023 All Rights Reserved
                                </p>
                            </div>
                        </div>
                    </DrawerItem>}
                </React.Fragment>;
            } else {
                return null;
            }
        }
        if (itemProps.id === 12) {
            if (props.drawerExpanded) {
                return <React.Fragment>
                    <DrawerItem {...others}>
                        <div title={"Start Tour"} id={"guided-tour-icon"}>
                            <span className={"k-item-text"} title={"Start Tour"}>
                                <span className={"k-icon"} title={"Start Tour"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                         className="bi bi-signpost-fill" viewBox="0 0 16 16" >
                                        <path
                                        d="M7.293.707A1 1 0 0 0 7 1.414V4H2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h5v6h2v-6h3.532a1 1 0 0 0 .768-.36l1.933-2.32a.5.5 0 0 0 0-.64L13.3 4.36a1 1 0 0 0-.768-.36H9V1.414A1 1 0 0 0 7.293.707"/>
                                    </svg>
                                </span>
                                                      Start Tour
                            </span>
                        </div>
                    </DrawerItem>
                </React.Fragment>;
            } else {
                return <React.Fragment>
                    <DrawerItem {...others} text={"Start Tour"} tipText={"Start Tour"}>
                        <div title={"Start Tour"}>
                           <span className={"k-item-text"}>
                                <span className={"k-icon"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-signpost-fill"
                                         viewBox="0 0 16 16">
                                      <path
                                          d="M7.293.707A1 1 0 0 0 7 1.414V4H2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h5v6h2v-6h3.532a1 1 0 0 0 .768-.36l1.933-2.32a.5.5 0 0 0 0-.64L13.3 4.36a1 1 0 0 0-.768-.36H9V1.414A1 1 0 0 0 7.293.707"/>
                                    </svg>
                                </span>
                            </span>
                        </div>
                    </DrawerItem>
                </React.Fragment>;
            }
        }
        const arrowDir = itemProps['data-expanded'] ? 'k-i-arrow-chevron-down' : 'k-i-arrow-chevron-right';
        return <React.Fragment>
            {itemProps.visible === false ? null : <DrawerItem {...others}>

                <Tooltip tooltipClassName={"side-nav-item-tooltip"}><span className={'k-item-text'}><span title={itemProps.text ? "" : itemProps.tipText} className={'k-icon ' + itemProps.icon} />{itemProps.text}</span></Tooltip>
                {itemProps['data-expanded'] !== undefined && <span className={"k-icon " + arrowDir} style={{
                    position: "relative",
                    right: 0
                }} />}
            </DrawerItem>}
        </React.Fragment>;
    };
    const determineActivateMediaRoute = () => {
        if (localStorage.getItem("selectedSegmentId")) {
            const selectedSegmentId = localStorage.getItem("selectedSegmentId");
            localStorage.removeItem("selectedSegmentId");
            return "/segmentation/activate/" + selectedSegmentId;
        } else {
            return "/activate-media";
        }
    }

    const [items, setItems] = useState([
        {
            text: 'Dashboard',
            icon: 'icon-dashboard',
            id: 1,
            selected: true,
            route: '/segmentation-dashboard',
            tipText: 'Dashboard'
            // style: {paddingLeft:"1.5rem", paddingTop:"1rem"}
        }, {
            text: 'Onboarding',
            icon: 'icon-onboard-sam',
            id: 2,
            route: '/onboarding',
            tipText: 'Onboarding'
            // style: {paddingLeft:"1.5rem"}
        }, {
            text: 'Audience Design',
            icon: 'icon-add-design',
            id: 3,
            route: '/audience-design',
            tipText: 'Audience Design'
            // style: {paddingLeft:"1.5rem"}
        },
        // FD: 10602
        // {
        //     text: 'Activate Media',
        //     icon: 'icon-flash-outline',
        //     id: 4,
        //     // route: localStorage.getItem("selectedSegmentId") ? "/segmentation/activate/"+localStorage.getItem("selectedSegmentId"):'/activate-media',
        //     route: '/activate-media',
        //     tipText: 'Activate Media'
        //     // style: {paddingLeft:"1.5rem"}
        // },
        // {
        //     text: 'Measurement',
        //     icon: 'fa fa-bar-chart measurement-graph-icon-sidebar',
        //     id: 4,
        //     route: '/attribution',
        //     style: {paddingLeft:"1.5rem"}
        // },

        {
            text: 'Admin Settings',
            icon: 'icon-settings',
            id: 5,
            route: '/admin-settings',
            tipText: 'Admin Settings'
            // style: {paddingLeft:"1.5rem"}
        },



        // {
        //     text: 'Admin Settings',
        //     icon: 'icon-th-large-outline',
        //     id: 5,
        //     ["data-expanded"]: false,
        //     style: {paddingLeft:"1.5rem"}
        // }, {
        //     text: 'User Profile',
        //     icon: 'icon-user-outline',
        //     id: 6,
        //     parentId: 5,
        //     route: '/user-profile',
        //     style: {paddingLeft:"2rem"},
        //     className: 'user-profile-class'
        // }, {
        // text: 'Permissions',
        //     icon: 'icon-key-outline',
        //     id: 7,
        //     parentId: 5,
        //     route: '/permission',
        //     style: {paddingLeft:"2rem"},
        //     className: 'permissions-class'
        // }, {
        //     text: 'File Transfer Settings',
        //     icon: 'icon-arrow-right-outline',
        //     id: 8,
        //     parentId: 5,
        //     route: '/file-transfer-settings',
        //     style: {paddingLeft:"2rem"},
        //     className: 'file-transfer-settings-class'
        // }, {
        //     text: 'Admin Tabs',
        //     icon: 'icon-arrow-right-outline',
        //     id: 11,
        //     parentId: 5,
        //     route: '/admin-settings',
        //     style: {paddingLeft:"2rem"},
        //     className: 'file-transfer-settings-class'
        // },
        {
            text: 'Help Center',
            icon: 'k-i-info-circle',
            id: 10,
            route:"/help-center",
            tipText: 'Help Center'
            // style: {paddingLeft:"1.5rem"}
        },
        {
            text: 'Sign Out',
            icon: 'icon-logout',
            id: 9,
            tipText: 'Sign Out'
            // style: {paddingLeft:"1.5rem"}
        },
        {
            text: 'Start Tour',
            tipText: 'Start Tour',
            id: 12,
            style: {position: "absolute", bottom: 50, backgroundColor:"none", alignItems: "center"},
            // className: 'guided-tour-wrapper',
        },
        {
            id: 11,
            style: {position: "absolute", bottom: 0, backgroundColor:"none"},
            // style: {paddingTop: "21rem", backgroundColor:"none"},
            className: 'contact-us-class'
        }
    ]);

    const onSelect = (ev) => {
        const currentItem = ev.itemTarget.props;
        if (currentItem.text === "Sign Out" || currentItem.tipText === "Sign Out") {
            signOut(ev);
        }
        const isParent = currentItem["data-expanded"] !== undefined;
        const nextExpanded = !currentItem["data-expanded"];
        const newData = items.map((item) => {
            const {
                selected,
                ["data-expanded"]: currentExpanded,
                id,
                ...others
            } = item;
            const isCurrentItem = currentItem.id === id;
            return {
                selected: isCurrentItem,
                ["data-expanded"]:
                    isCurrentItem && isParent ? nextExpanded : currentExpanded,
                id,
                ...others,
            };
        });
        if (currentItem.id === 12 && pathname.localeCompare("/segmentation-dashboard") === 0 ) {
            // console.log("1");
            dashboardTour.start();
        }else if(currentItem.id === 12 && pathname.localeCompare("/onboarding") === 0){
            // console.log("2");
            onboardingTour.start();
        }else if(currentItem.id === 12 && pathname.localeCompare("/audience-design") === 0){
            // console.log("3");
            audiencedesignTour.start();
        }else if(currentItem.id === 12 && pathname.localeCompare("/admin-settings") === 0){
            // console.log("4");
            adminsettingsTour.start();
        }else if(currentItem.id === 12 && ev.itemTarget.props.route === undefined){
                return
        }
        props.history.push(ev.itemTarget.props.route);
        // if (localStorage.getItem("selectedSegmentId")) {
        //     const selectedSegmentId = localStorage.getItem("selectedSegmentId");
        //     newData[3].route = "/segmentation/activate/" + selectedSegmentId;
        //     props.history.push("/segmentation/activate/" + selectedSegmentId);
        // } else {
        //     newData[3].route =  "/activate-media";
        //     props.history.push(ev.itemTarget.props.route);
        // }
        setItems(newData);
        localStorage.removeItem("selectedSegmentId");
    };

    const signOut = async (e) => {
        // e.preventDefault();
        try {
            await semcastAxios.get(SIGN_OUT_API, {withCredentials: true});
            removeDataFromStorage();

            // reset login attempts cookie
            document.cookie = "attempts=0";

            window.location.href = "/"
            // history.push("/");
        } catch (e) {
        }
    };

    const setSelectedItem = pathName => {
        let currentPath = items.find(item => item.route === pathName);

        if (currentPath.text) {
            return currentPath.text;
        }
    };
    let selected = setSelectedItem("/segmentation-dashboard");
    // setDrawerExpanded(!props.menuToggle);

    const data = items.map((item) => {
        const {parentId, ...others} = item;
        if (parentId !== undefined) {
            const parent = items.find((parent) => parent.id === parentId);
            if (!props.drawerExpanded) {
                return {...others, text: '', visible: parent["data-expanded"]};
            } else {
                return {...others, visible: parent["data-expanded"]};
            }
        }
        if (!props.drawerExpanded) {
            if (item.text === "Activate Media") {
                return {text: '', icon: item.icon, id: item.id, disabled: true, selected: item.selected, route: item.route, tipText: item.tipText};
            }
            return {text: '', icon: item.icon, id: item.id, selected: item.selected, route: item.route, tipText: item.tipText};
        } else {
            // 09-20-23 - RWK - Disable until page route is available
            if (item.text === "Activate Media") {
                item.disabled = true;
            }
            return item;
        }
    });

    return (
        <>
        <div>
            <SideNavigationBarHeader drawerExpanded={props.drawerExpanded} />
        </div>
        <div className="side-nav-item-wrapper">
            <Drawer
                expanded={props.drawerExpanded}
                mode={'push'}
                mini={true}
                miniWidth={65}
                width={235}
                items={data}
                item={CustomItem}
                onSelect={onSelect} style={{height:"80rem"}}>
                <DrawerContent>
                    {props.children}
                </DrawerContent>
            </Drawer>
        </div>
        </>
    )

}

export default withRouter(SideNavigationBar);