import React, {useEffect, useRef, useState} from "react";
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs';
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {DateInput, DatePicker, DateRangePicker} from "@progress/kendo-react-dateinputs";
import {Button} from "@progress/kendo-react-buttons";
import {Checkbox} from "@progress/kendo-react-inputs";
import {Label} from "@progress/kendo-react-labels";
import Loader from "../Loader";
import {useFormik} from "formik";
import {
    semcastAxios,
    showErrorToastMessage,
    showSuccessToastMessage
} from "../../../Utils/Common";
import {
    ACTIVATION_ID_PLACE_HOLDER,
    FTP_SETTINGS,
    FTP_STRING,
    IDENTITY_REPORT_ACTIVATION_API,
    IDENTITY_REPORT_TRACKING_PIXEL_API,
    SEGMENT_ID_PLACE_HOLDER,
    S3_STRING,
    SUCCESS_STATUS, IDENTITY_REPORT_IDX_PIXEL_API
} from "../../../api/constants";
import { getFTPSettings } from "../../../actions/OnBoardingActions";
import "./Identity.css";

const IdentityActivation = (props) => {
    const [visible, setVisible] = useState(props.visible);
    const [isLoaded, setIsLoaded] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState(null);
    const [ftpSettingsId, setFtpSettingsId] = useState(0);
    const [allSavedSettings, setAllSavedSettings] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [defaultStartDate, setDefaultStartDate] = useState(
        (props.Identity.profileFormik ?
            new Date(props.Identity.profileFormik.values.selectedSegments[0].createdAt) : null)
    );

    const [currentConnection, setCurrentConnection] = useState(
        {text: '', id: 0}
    );

    const [currentScope, setCurrentScope] = useState({text: "All", id: "A"})

    const selectedSegment = props.Identity.profileFormik?.values?.selectedSegments[0];

    // current connection (default / chosen) details
    const [connection, setConnection] = useState(
        {
            id: 0,
            connectionName: '',
            protocol: 'ftps',
            host: '',
            port: '',
            destinationDir: '',
            delimiter: '',
            transferMode: 'Passive',
            username: '',
            password: '',
        }
    );
    const [tagId, setTagId] = useState(0);

    useEffect(() => {
        const getSettings = async() => {
            try {
                const settings = await getFTPSettings();
                if (settings && settings.data.length) {
                    setAllSavedSettings(settings.data);
                    const setting = settings.data[settings.data.length - 1];
                    if (setting) {
                        setCurrentConnection({
                            ...currentConnection,
                            ['text']: setting.connection_name,
                            ['id']: setting.id,
                            ['protocol']: setting.transfer_protocol
                        });
                    } else {
                        setCurrentConnection({text: '', id: 0});
                    }
                    setConnection(setting);
                    setFtpSettingsId(setting.id);
                }
            } catch {
                showErrorToastMessage("Could not access Connection information.");
            } finally {
                setIsLoaded(true);
            }
        }
        getSettings();
    }, []);

    useEffect(() => {
    }, [props.segName]);

    useEffect(() => {
        if (props.Identity.profileFormik.values &&
            props.Identity.profileFormik.values.selectedSegments[0] &&
            props.Identity.profileFormik.values.selectedSegments[0].createdAt) {
            const createdAtDate = new Date(props.Identity.profileFormik.values.selectedSegments[0].createdAt);
            const formattedDate =
                createdAtDate.toLocaleString("default", {year: "numeric"}) + "-" +
                createdAtDate.toLocaleString("default", {month: "2-digit"})  + "-" +
                createdAtDate.toLocaleString("default", {day: "2-digit"});
            setStartDate(formattedDate);
        }
    }, [])

    useEffect(()  => {
        if (startDate !== "" && currentConnection.id > 0) {
            setDisableSubmit(false);
        }
    }, [startDate, currentConnection.id]);

    useEffect(() => {
        if(selectedSegment.objectType.toLowerCase() !== "idx-tag"){
           if(selectedSegment.subType.toLowerCase() === "consumer" || selectedSegment.subType.toLowerCase() === "idx-consumer"){
               setCurrentScope({text: "Consumer", id: "C"});
           }else if(selectedSegment.subType.toLowerCase() === "business"|| selectedSegment.subType.toLowerCase() === "idx-business"){
               setCurrentScope({text: "Business", id: "B"})
           }
        }
    }, []);

    function getAllIds(obj) {
        let ids = [];

        // Helper function to recursively extract ids
        function extractIds(currentObj) {
            // Add the current object's id to the ids array
            if (currentObj.id !== undefined) {
                ids.push(currentObj.id);
            }

            // Check if the current object has children and recurse
            if (Array.isArray(currentObj.children)) {
                currentObj.children.forEach(child => extractIds(child));
            }
        }

        // Start the extraction with the main object
        extractIds(obj);

        return ids;
    }

    useEffect(()=>{
        let tagId;
        let allIds = [];
        // must be a child of a IDX-Segment. Selected Segment must be a Segment or IDX-Segment
        for (const child of props.items) { //check all dashboard items
            if(child.objectType === 'IDX-Tag' && child.children.length > 0){ //is IDX Tag and has children
                allIds = getAllIds(child); //getting all ids under an IDX-Tag in the current page
                if(allIds.find(item=>item === props.Identity.profileFormik.values.selectedSegments[0]?.id)){
                    tagId = child.id;
                }
            }
        }
            setTagId(tagId);
    },[])

    const CustomStartDateInput = (props) => {
        return (
            <>
                <DateInput {...props} format="yyyy-MM-dd" placeholder="YYYY-MM-DD"
                           label={<span className={"required-asterisk"}>Start Date</span>}/>
            </>
        )
    };

    const CustomEndDateInput = (props) => {
        return (
            <>
                <DateInput {...props} format="yyyy-MM-dd" placeholder="YYYY-MM-DD" label={"End Date"} />
            </>
        )
    };

    const onDatesChange = (e) => {
        if (e.value.start) {
            const formattedDate =
                e.value.start.toLocaleString("default", {year: "numeric"}) + "-" +
                e.value.start.toLocaleString("default", {month: "2-digit"})  + "-" +
                e.value.start.toLocaleString("default", {day: "2-digit"});
            setStartDate(formattedDate);
        } else {
            setDisableSubmit(true);
        }
        if (e.value.end) {
            const formattedDate =
                e.value.end.toLocaleString("default", {year: "numeric"}) + "-" +
                e.value.end.toLocaleString("default", {month: "2-digit"})  + "-" +
                e.value.end.toLocaleString("default", {day: "2-digit"});
            setEndDate(formattedDate);
        } else {
            setEndDate(null);
        }
    };

    const connections = allSavedSettings?.map((item, i) => {
        if (allSavedSettings.length > 0) {
            return (
                {text: item.connection_name, id: item.id, protocol: item.transfer_protocol}
            )
        } else {
            return (
                {text: "No Saved Connections", id: 0}
            )
        }
    });

    const scopes = [
        {text: "All", id: "A"},
        {text: "Consumer", id: "C"},
        {text: "Business", id: "B"}
    ];

    const handleConnectionChange = (e) => {
        console.log(e.value);
        const setting = allSavedSettings.filter(ftpSetting => ftpSetting.id === e.value.id);
        setConnection(setting[0]);
        setCurrentConnection({
            ...currentConnection,
            ['text']: e.value.text,
            ['id']: e.value.id
        });
        setFtpSettingsId(e.value.id);
    };

    const handleScopeChange = (e) => {
        console.log(e.value);
        setCurrentScope({
            ...currentScope,
            ['text']: e.value.text,
            ['id']: e.value.id
        });
    };


    const identityReport = async () => {
        setDisableSubmit(true);
        let tempId;
        try {
            if(selectedSegment.objectType.toLowerCase()  !== 'idx-tag'){
                tempId = selectedSegment.id;
            }else {
                tempId = null;
            }
            const payload = {
                segmentId: tempId,
                idxTagId: tagId,
                start_date: startDate,
                end_date: endDate,
                ftpsettings_id: currentConnection.id,
                scope: currentScope.id
            };
            let response = await semcastAxios.post(IDENTITY_REPORT_IDX_PIXEL_API,
                payload,
                {withCredentials: true})
                .then(async (res) => {
                    // console.log("identity report res:", res);
                    if (res.data.status === SUCCESS_STATUS) {
                        showSuccessToastMessage("Identity Report Request Sent Successfully.");
                        // FD: 10863 (reversal of 10839)
                        props.reloadAfterIdentity();
                        setVisible(false);
                    }
                });
        } catch (error) {
            showErrorToastMessage(error.response.data.data);
            props.reloadAfterIdentity();
        } finally {
            setVisible(false);
            setDisableSubmit(false);
        }
    }

    return (
        <>
            <div id="identityReport">
                {visible && (
                    <Dialog
                        title={"Identity Report For IDX - "+props.segName}
                        width={650}
                        closeIcon={false}
                        className={"dialog-standard"}
                    >
                        {/*<div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12">*/}
                        <div className={"identity-controls-content"}>
                            <div className="form-group">
                                <DateRangePicker
                                    startDateInput={CustomStartDateInput}
                                    endDateInput={CustomEndDateInput}
                                    onChange={onDatesChange}
                                    className={"identity-date-picker"}
                                    defaultValue={{start: defaultStartDate, end: null}}
                                />
                            </div>

                            <div>
                                <Label className={"identity-connection-label required-asterisk"}>Direct Report To
                                    Connection</Label>
                            </div>
                            <div>
                                {isLoaded ? (
                                    <DropDownList
                                        data={connections}
                                        // itemRender={itemRender}
                                        textField="text"
                                        dataItemKey="id"
                                        onChange={(e) => handleConnectionChange(e)}
                                        value={currentConnection}
                                        fillMode={"outline"}
                                        className="identity-connection-settings"
                                        disabled={allSavedSettings.length === 0}
                                        style={{fontSize: "14px"}}
                                    />
                                ) : (
                                    <Loader height={"100%"}/>
                                )}
                            </div>
                            <br/>
                            <div>
                                <Label className={"identity-connection-label required-asterisk"}>Scope</Label>
                            </div>
                            <div>
                                <DropDownList
                                    data={scopes}
                                    textField="text"
                                    dataItemKey="id"
                                    onChange={(e) => handleScopeChange(e)}
                                    value={currentScope}
                                    fillMode={"outline"}
                                    disabled={selectedSegment.objectType.toLowerCase() !== "idx-tag" ? 'disabled' : ''}
                                    className={`identity-scope ${selectedSegment.objectType.toLowerCase() !== "idx-tag" ? 'disabled' : ''}`}
                                    style={{fontSize: "14px"}}
                                />
                            </div>
                            <br/>
                        </div>
                        <DialogActionsBar layout={"end"}>
                            <Button
                                // className="dialog-cancel"
                                className={"button-standard button-white"}
                                onClick={() => {
                                    props.cancelSubscriber(undefined)
                                }}>
                                Cancel
                            </Button>
                            <Button
                                disabled={disableSubmit}
                                className={"button-standard button-submit"}
                                onClick={identityReport}>
                                Submit
                            </Button>
                        </DialogActionsBar>
                    </Dialog>)}
            </div>
        </>
    );
}

export default IdentityActivation;
