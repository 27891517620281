import React, {useEffect, useRef, useState} from "react";
import {NavLink, useParams, useHistory} from 'react-router-dom'
import {
    ActivateView,
    COMPANIES,
    curateCOMPANIES,
    dspCONNECTIONS,
    MeasurementTiles,
    IndirectTiles,
    SupplySideTiles,
    SocialTiles,
    DirectIntegrationsTiles,
    AllInOneTiles,
    DirectMailTiles
} from "../../helpers/ActivateSegmentHelpers";
import ActivateSegmentForm from "../Components/Segmentation/ActivateSegmentForm"
import LoadOverlay from "../Components/SharedComponents/LoadOverlay"
import "./ActivateSegment.css"
import {
    ACTIVATE_SEGMENT_ROUTE_PREFIX,
    ACTIVATION_CREATIVE_TAGs, IDENTITY_REPORT_TRACKING_PIXEL_API, PLATFORM_NAME_PLACE_HOLDER,
    SEGMENT_ID_PLACE_HOLDER, SEGMENT_REPORT_REGENERATE_API,
    SEGMENTS_REPORT_DETAIL,
    SUCCESS_STATUS
} from "../../api/constants";
import {ExcelExport, ExcelExportColumn} from "@progress/kendo-react-excel-export";
import {DialogActionsBar} from "@progress/kendo-react-dialogs";
import {semcastAxios, showErrorToastMessage, showSuccessToastMessage} from "../../Utils/Common";
import {getFTPSettings} from "../../actions/OnBoardingActions";

function ActivateSegment() {
 //   const {id} = useParams();
    const searchParams = new URLSearchParams(document.location.search)
    const id = searchParams.get('id');
    const [uploadedRecords, setUploadedRecords] = useState([]);
    const [totalRecordsUploaded, setTotalRecordsUploaded] = useState(0)

    const [selectedCompany, setSelectedCompany] = useState(undefined);

    const [selectedCreativeTags, setSelectedCreativeTags] = useState([]);
    const [currentConnection, setCurrentConnection] = useState(
        {text: '', id: 0}
    );
    const [allSavedSettings, setAllSavedSettings] = useState([]);

    const [hiddenOnBoardingCompanies, setHiddenOnBoardingCompanies] = useState([]);

    const [companyFormLoader, setCompanyFormLoader] = useState(false)
    const [companiesLoader, setCompaniesLoader] = useState(false);

    const [activateView, setActivateView] = useState(new ActivateView());
    const [excelData, setExcelData] = useState([]);
    // const activateView = new ActivateView();
    const myRef = useRef(null)
    const _exporter = React.createRef();
    let history = useHistory();

    const executeScroll = () => {
        myRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})
    }

    const reloadEventFromChild = async () => {
        setCompaniesLoader(true)
        await activateView.fetchAlreadyActivatedData(id).then(r => {
            activateView.markCompanyBlocks();
            setCompaniesLoader(false);
        }).catch((err) => {
            if (err.status === 403 || err.status === 401) {
                showErrorToastMessage("Your account did not have the permission to proceed, please contact support", 7)
                history.push('/segmentation-dashboard');
            }
        });
    }
    const formCloseEvent = async () => {
        setSelectedCompany(null)
    }

    const compareDates = (updated_at, created_at) => {
        let updatedAt = new Date(updated_at).toLocaleDateString();
        // let lastProfileUpdatedAt = new Date(last_profile_updated_at).toLocaleDateString();
        let createdAt = new Date(created_at).toLocaleDateString();
        let today = new Date().toLocaleDateString();


        try{
            if (createdAt !== today && updatedAt !== today) {
                return true;
            } else {
                return false;
            }
        }catch (e) {
            console.log("compareDatesError=", e);
        }

    };

    const handleGenerateReportBtn = async (segmentId) => {
        try {
            let response = await semcastAxios.post(SEGMENT_REPORT_REGENERATE_API + segmentId + '/regenerate' , {
                withCredentials: true,
            });
            if (response.data.status === SUCCESS_STATUS) {
                showSuccessToastMessage("Regenerating Report..", 30)
                return response.data;
            }
        } catch (error) {
            showErrorToastMessage("error: " + error);
            console.log("error: " + error)
        }
    }
    const handleGetReportBtn = async (segmentId) => {
        let segment = activateView.segment;
        // let updatedAt = new Date(segment.updatedAt).toLocaleDateString();
        let today = new Date().toLocaleDateString();
        let response = await semcastAxios.get(SEGMENTS_REPORT_DETAIL.replace(SEGMENT_ID_PLACE_HOLDER, segmentId), {withCredentials: true});
        if (response.data.status !== SUCCESS_STATUS) return;
        let updatedAt = new Date(response.data.data.updated_at).toLocaleDateString();
        let createdAt = new Date(response.data.data.created_at).toLocaleDateString();
        if(compareDates(updatedAt, createdAt) || response.data.data.profile_report_details == null){
            await handleGenerateReportBtn(segmentId);
            history.push('/segmentation-dashboard');
            }else {
            try {
                let response = await semcastAxios.get(SEGMENTS_REPORT_DETAIL.replace(SEGMENT_ID_PLACE_HOLDER, segmentId), {withCredentials: true})
                if (response.data.status === SUCCESS_STATUS) {
                    showSuccessToastMessage("Getting Report", 1);
                    return (
                        history.push(response.data.data.segment_type === "CONSUMER" ? (
                            {
                                pathname: `/dashboard`,
                                state: {
                                    notes: response.data.data.notes, // BKR
                                    segment_name: response.data.data.segment_name,
                                    data: response.data.data.profile_report_details,
                                    segment_id: response.data.data.id,//MZHU
                                    profile_data_obsolete: response.data.data.profile_data_obsolete, //FD#11503
                                }
                            }
                        ) : (
                            {
                                pathname: `/business-dashboard`,
                                state: {
                                    notes: response.data.data.notes, // BKR
                                    segment_name: response.data.data.segment_name,
                                    data: response.data.data.profile_report_details,
                                    segment_id: response.data.data.id,//MZHU
                                    profile_data_obsolete: response.data.data.profile_data_obsolete, //FD#11503
                                },
                            }
                        ))
                    )
                }
            } catch (error) {
                showErrorToastMessage("error: " + error);
                console.log("error: " + error)
            }
        }
    }

    const excelExport = () => {
        if (_exporter.current) {
            _exporter.current.save();
        }
    };

    const getSelectedCreativeTagInfo = async(company) => {
        if (company === 'semcasting' || company === 'semcastingmeasurement' || company === 'instepmeasurement' || company === 'keywordswhitelist') return;
        const url= ACTIVATION_CREATIVE_TAGs.replace(PLATFORM_NAME_PLACE_HOLDER, company);
        let response = null;
        try {
            response = await semcastAxios.get((url), {withCredentials: true});
        } catch(error) {
        }
        if (response) {
            setSelectedCreativeTags(response.data.creativeTags);
        } else {
            setSelectedCreativeTags([]);
        }
    }
    const connections = allSavedSettings?.map((item, i) => {
        if (allSavedSettings.length > 0) {
            return (
                {text: item.connection_name, id: item.id, protocol: item.transfer_protocol}
            )
        } else {
            return (
                {text: "No Saved Connections", id: 0}
            )
        }
    });
    const getFTPConnectionList = async(company) => {
        if (company === 'semcasting_directmail' || company === 'keywordswhitelist') {
            getFTPSettings().then((res) => {
                if (res && res.data.length) {
                    setAllSavedSettings(res.data);
                    const setting = res.data[res.data.length-1];
                    if(setting) {
                        setCurrentConnection({
                            ...currentConnection,
                            ['text']: setting.connection_name,
                            ['id']: setting.id,
                            ['protocol']: setting.transfer_protocol
                        })
                    } else {
                        setCurrentConnection({text: '', id: 0});
                    }
                }
            });
        };
    }
    useEffect(() => {
        async function fetchDetails() {
            try {
                activateView.fetchAccountPricing(id).then() //can run async

                setCompaniesLoader(true)
                const values = await Promise.allSettled([activateView.fetchAccountPermissions()
                    , activateView.fetchAlreadyActivatedData(id)
                    , activateView.fetchSegment(id)])
                values.map(value=> {
                    if (value.status === "rejected") {
                        showErrorToastMessage("Your account did not have the permission to proceed, please contact support")
                        history.push("/segmentation-dashboard");
                    }

                })
                //operations with account permisssions data
                setHiddenOnBoardingCompanies(activateView.hiddenCompaniesArray.map(company => company.trim()))

                // console.log("hiddenOnBoardingCompanies=", hiddenOnBoardingCompanies);

                // console.log("fetchDetailsActivateView=", activateView);
                //operations with activated data
                activateView.markCompanyBlocks()
                try {
                    //operations with segment data
                    setTotalRecordsUploaded(activateView.segment?.total_records_uploaded)
                    setUploadedRecords(activateView.segmentationUploadedRecords(activateView.segment))
                } catch (e) {
                }
                setCompaniesLoader(false)
            } catch (e) {
                setCompaniesLoader(false)
            } finally {
            }
        }

        fetchDetails().then(r => "").catch((err) => {
            if(err.status === 403 || err.status === 401 ){
                console.error("ActivateSegmentFetchDetailsError=", err);
                history.push('/segmentation-dashboard');
            }
        });
    }, []);

    useEffect(() => {
    }, [activateView.companyTileColorTextData]);

    useEffect(() => {

        const newExcelData = [{"text": "Uploaded Records", "size": totalRecordsUploaded}, ...activateView.segmentationUploadedRecords(activateView.segment)];
        setExcelData(newExcelData.filter(item => item.size > 0));

    }, [uploadedRecords]);

    const uploadedRecordsList = uploadedRecords.length > 0
        && uploadedRecords.map((record, i) => {
            return (
                <>
                    {record?.size > 0 &&
                        <div className="list-group-item">
                            {record.text === 'CPM' ? (
                                <>
                                    <div className={"record-text"}>{record.text}</div>
                                    <span style={{minWidth:'10px'}}></span>
                                    <div className="record-number">{record?.size?.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 2,
                                    })}</div>
                                </>
                            ) : (
                                <>
                                    <div className={"record-text"}>{record.text}</div>
                                    <span style={{minWidth:'10px'}}></span>
                                    <div className="record-number">{record?.size?.toLocaleString()}</div>
                                </>
                            )}
                        </div>
                    }
                </>
            )
        }, this);


    const allInOneTileList = activateView.companyTileColorTextData && Object.keys({...AllInOneTiles}).map((company, index) => {
        return (
            <>
                {!hiddenOnBoardingCompanies.includes(company) &&
                    <div className="tile-container">
                        <button type="button" onClick={() => {
                            setCompanyFormLoader(true)
                            setSelectedCompany(null);
                            setTimeout(() => {
                                setSelectedCompany(AllInOneTiles[company]);
                                getSelectedCreativeTagInfo(company);
                                setCompanyFormLoader(false)
                                executeScroll()
                            }, 500)
                        }}
                        >
                            <div className={`activate-segment-tile ${activateView.companyTileColorTextData[company]?.markerClass}`}>
                                {activateView.companyTileColorTextData[company]?.faIconClass &&
                                    <i className={`status-icon ${activateView.companyTileColorTextData[company]?.faIconColorClass} fa ${activateView.companyTileColorTextData[company]?.faIconClass}`}/>}
                                <div className="all-in-one-tiles">
                                    <div className="tile-container">
                                        <img src={`/images/legacy_audience_images/${AllInOneTiles[company]?.companyBlockOptions?.img}`} className=""/>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </div>
                }
            </>
        )
    }, this);
    const directIntegrationTileList = activateView.companyTileColorTextData && Object.keys({...DirectIntegrationsTiles}).map((company, index) => {
        // console.log("DirectIntegrationsTiles=", DirectIntegrationsTiles);
        // console.log("DirectIntegrationsTilesHiddenOnBoardingCompanies=", hiddenOnBoardingCompanies);
        return (
            <>
                {!hiddenOnBoardingCompanies.includes(company) &&
                    <div className="tile-container">
                        <button type="button" onClick={() => {
                            setCompanyFormLoader(true)
                            setSelectedCompany(null);
                            getFTPConnectionList(company);
                            setTimeout(() => {
                                setSelectedCompany(DirectIntegrationsTiles[company])
                                getSelectedCreativeTagInfo(company);
                                setCompanyFormLoader(false)
                                executeScroll()
                            }, 500)
                        }}
                        >
                            <div className={`activate-segment-tile ${activateView.companyTileColorTextData[company]?.markerClass}`}>
                                {activateView.companyTileColorTextData[company]?.faIconClass &&
                                    <i className={`status-icon ${activateView.companyTileColorTextData[company]?.faIconColorClass} fa ${activateView.companyTileColorTextData[company]?.faIconClass}`}/>}
                                <div className="plan-semcast">
                                    <div className="company-img">
                                        <img src={`/images/legacy_audience_images/${DirectIntegrationsTiles[company]?.companyBlockOptions?.img}`} className=""/>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </div>
                }
            </>
        )
    }, this);
    const socialTileList = activateView.companyTileColorTextData && Object.keys({...SocialTiles}).map((company, index) => {
        // console.log("SocialTiles=", SocialTiles);
        // console.log("socialTileListHiddenOnBoardingCompanies=", hiddenOnBoardingCompanies);
        return (
            <>
                {!hiddenOnBoardingCompanies.includes(company) &&
                    <div className="tile-container">
                        <button type="button" onClick={() => {
                            setCompanyFormLoader(true)
                            setSelectedCompany(null);
                            setTimeout(() => {
                                setSelectedCompany(SocialTiles[company]);
                                getSelectedCreativeTagInfo(company);
                                setCompanyFormLoader(false)
                                executeScroll()
                            }, 500)
                        }}
                        >
                            <div className={`activate-segment-tile ${activateView.companyTileColorTextData[company]?.markerClass}`}>
                                {activateView.companyTileColorTextData[company]?.faIconClass &&
                                    <i className={`status-icon ${activateView.companyTileColorTextData[company]?.faIconColorClass} fa ${activateView.companyTileColorTextData[company]?.faIconClass}`}/>}
                                <div className="plan-semcast">
                                    <div className="company-img">
                                        <img src={`/images/legacy_audience_images/${SocialTiles[company]?.companyBlockOptions?.img}`} className=""/>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </div>
                }
            </>
        )
    }, this);
    const supplySideTileList = activateView.companyTileColorTextData && Object.keys({...SupplySideTiles}).map((company, index) => {
        // console.log("SupplySideTiles=", SupplySideTiles);
        // console.log("supplySideTileListHiddenOnBoardingCompanies=", hiddenOnBoardingCompanies);
        return (
            <>
                {!hiddenOnBoardingCompanies.includes(company) &&
                    <div className="tile-container">
                        <button type="button" onClick={() => {
                            setCompanyFormLoader(true)
                            setSelectedCompany(null);
                            setTimeout(() => {
                                setSelectedCompany(SupplySideTiles[company]);
                                getSelectedCreativeTagInfo(company);
                                setCompanyFormLoader(false)
                                executeScroll()
                            }, 500)
                        }}
                        >
                            <div className={`activate-segment-tile ${activateView.companyTileColorTextData[company]?.markerClass}`}>
                                {activateView.companyTileColorTextData[company]?.faIconClass &&
                                    <i className={`status-icon ${activateView.companyTileColorTextData[company]?.faIconColorClass} fa ${activateView.companyTileColorTextData[company]?.faIconClass}`}/>}
                                <div className="plan-semcast">
                                    <div className="company-img">
                                        <img src={`/images/legacy_audience_images/${SupplySideTiles[company]?.companyBlockOptions?.img}`} className=""/>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </div>
                }
            </>
        )
    }, this);

    const measurementTileList = activateView.companyTileColorTextData && Object.keys({...MeasurementTiles}).map((company, index) => {
         // console.log("MeasurementTiles=", MeasurementTiles);
         // console.log("measurementTileListHiddenOnBoardingCompanies=", hiddenOnBoardingCompanies);

        return (
            <>
                {!hiddenOnBoardingCompanies.includes(company.trim()) &&
                    <div className="tile-container">
                        <button type="button" onClick={() => {
                            setCompanyFormLoader(true)
                            setSelectedCompany(null);
                            setTimeout(() => {
                                setSelectedCompany(MeasurementTiles[company]);
                                getSelectedCreativeTagInfo(company);
                                setCompanyFormLoader(false)
                                executeScroll()
                            }, 500)
                        }}
                        >
                            <div
                                className={`activate-segment-tile ${activateView.companyTileColorTextData[company]?.markerClass}`}>
                                {activateView.companyTileColorTextData[company]?.faIconClass &&
                                    <i className={`status-icon ${activateView.companyTileColorTextData[company]?.faIconColorClass} fa ${activateView.companyTileColorTextData[company]?.faIconClass}`}/>}
                                <div className={`plan-semcast ${company}`}>
                                    <div className="company-img">
                                        <img
                                            src={`/images/legacy_audience_images/${MeasurementTiles[company]?.companyBlockOptions?.img}`}
                                            className=""/>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </div>
                }
            </>
        )
    }, this);

    const indirectTileList = activateView.companyTileColorTextData && Object.keys({...IndirectTiles}).map((company, index) => {
        // console.log("IndirectTiles=", IndirectTiles);
        // console.log("indirectTileListHiddenOnBoardingCompanies=", hiddenOnBoardingCompanies);
        return (
            <>
                {!hiddenOnBoardingCompanies.includes(company) &&
                    <div className="tile-container">
                        <button type="button" onClick={() => {
                            setCompanyFormLoader(true)
                            setSelectedCompany(null);
                            getSelectedCreativeTagInfo(company);
                            setTimeout(() => {
                                setSelectedCompany(IndirectTiles[company])
                                setCompanyFormLoader(false)
                                executeScroll()
                            }, 500)
                        }}
                        >
                            <div className={`activate-segment-tile ${activateView.companyTileColorTextData[company]?.markerClass}`}>
                                {activateView.companyTileColorTextData[company]?.faIconClass &&
                                    <i className={`status-icon ${activateView.companyTileColorTextData[company]?.faIconColorClass} fa ${activateView.companyTileColorTextData[company]?.faIconClass}`}/>}
                                <div className="plan-semcast">

                                    <div className="company-img">
                                        <img
                                            src={`/images/legacy_audience_images/${IndirectTiles[company]?.companyBlockOptions?.img}`}
                                            className=""/>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </div>
                }
            </>
        )
    }, this);
    const directMailTileList = activateView.companyTileColorTextData && Object.keys({...DirectMailTiles}).map((company, index) => {
        // console.log("DirectMailTiles=", DirectMailTiles);
        // console.log("DirectTileListHiddenOnBoardingCompanies=", hiddenOnBoardingCompanies);
        return (
            <>
                {!hiddenOnBoardingCompanies.includes(company) &&
                    <div className="tile-container">
                        <button type="button" onClick={() => {
                            setCompanyFormLoader(true)
                            setSelectedCompany(null);
                            getFTPConnectionList(company);
                            setTimeout(() => {
                                setSelectedCompany(DirectMailTiles[company])
                                setCompanyFormLoader(false)
                                executeScroll()
                            }, 500)
                        }}
                        >
                            <div className={`activate-segment-tile ${activateView.companyTileColorTextData[company]?.markerClass}`}>
                                {activateView.companyTileColorTextData[company]?.faIconClass &&
                                    <i className={`status-icon ${activateView.companyTileColorTextData[company]?.faIconColorClass} fa ${activateView.companyTileColorTextData[company]?.faIconClass}`}/>}
                                <div className="plan-semcast">

                                    <div className="company-img">
                                        <img
                                            src={`/images/legacy_audience_images/${DirectMailTiles[company]?.companyBlockOptions?.img}`}
                                            className=""/>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </div>
                }
            </>
        )
    }, this);


    return (

        <div className="activate-segment-content">
            <div className="container-fluid">
                <LoadOverlay active={companiesLoader}>
                    <div className="row nav-pills samcast-menu">
                        <div className="col-lg-9">
                            <div className="tab-content">
                                <div className="card p-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12"><h3
                                                className="w-100">{activateView?.segment?.segment_name}</h3>
                                            </div>
                                        </div>
                                        <div className="company-list">
                                            {/*{companyList}*/}
                                            {/*<legend className={'k-form-legend spacingLine'}>Curate Deals</legend>*/}
                                            {/*{curateCompanyList}*/}
                                            {/*<legend className={'k-form-legend spacingLine'}> Special DSP Connection*/}
                                            {/*</legend>*/}
                                            {/*{dspCompanyList}*/}
                                            {/*FD: 10254 - Hide ADS Dash Tile*/}
                                            {/*<legend className={'k-form-legend spacingLine'}>All In One</legend>*/}
                                            {/*{allInOneTileList}*/}
                                            <div className={'license-holder-legend'} style={{color:'skyblue',width:"100%"}}>For License Holders:</div>
                                            <legend className={'k-form-legend spacingLine'}>Direct Integrations</legend>
                                            {directIntegrationTileList}
                                            <legend className={'k-form-legend spacingLine'}>Social</legend>
                                            {socialTileList}
                                            <legend className={'k-form-legend spacingLine'}>Supply Side - Deal ID</legend>
                                            {supplySideTileList}
                                            <legend className={'k-form-legend spacingLine'}>Measurement / Restricted</legend>
                                            {measurementTileList}
                                            <legend className={'k-form-legend spacingLine'}>Indirect</legend>
                                            {indirectTileList}
                                            <legend className={'k-form-legend spacingLine'}>Direct Mail</legend>
                                            {directMailTileList}
                                        </div>
                                    </div>
                                </div>
                                {/*<div ref={myRef}>*/}
                                {/*    <LoadOverlay active={companyFormLoader}>*/}
                                {/*        {selectedCompany?.company &&*/}
                                {/*            <ActivateSegmentForm*/}
                                {/*                broadcastFormCloseEvent={formCloseEvent}*/}
                                {/*                broadcastReloadEvent={reloadEventFromChild}*/}
                                {/*                data={{companyData: selectedCompany, creativeTags: selectedCreativeTags, activateView: activateView}}/>*/}
                                {/*        }*/}
                                {/*    </LoadOverlay>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="card">
                                <div className="card-body" style={{display:'flex'}}>
                                    {/*<div className="row">*/}
                                    <div className="uploaded-records">
                                        <h4>UPLOADED RECORDS</h4>
                                        {totalRecordsUploaded && totalRecordsUploaded > 0 ?
                                            <h1 style={{}}>{totalRecordsUploaded?.toLocaleString()}</h1> : null}
                                        <div className="basic-list-group">
                                            <div className="list-group">
                                                {uploadedRecordsList}
                                            </div>
                                        </div>
                                        <div className={"profile-excel-buttons-wrapper"} style={{width:"inherit",display:"flex",justifyContent:"center"}}>
                                            <button
                                                // style={{paddingLeft:"15px",paddingRight:"15px"}}
                                                className="btn btn-primary shadow"
                                                onClick={()=>handleGetReportBtn(id)}
                                            >
                                                Profile
                                            </button>
                                            <button
                                                className="btn btn-primary shadow"
                                                onClick={excelExport}
                                            >
                                                <i className="fa fa-arrow-down"></i>Excel
                                            </button>
                                        </div>
                                        <ExcelExport
                                            data={excelData}
                                            collapsible={true}
                                            fileName={activateView.segment.segment_name + "_Segment_Summary.xlsx"}
                                            ref={_exporter}
                                        >
                                            <ExcelExportColumn field="text" title="Categories" width={270} />
                                            <ExcelExportColumn field="size" title="Size" />
                                        </ExcelExport>
                                    </div>
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                        <div className={"form-card col-lg-9"} ref={myRef}>
                            <LoadOverlay active={companyFormLoader}>
                                {selectedCompany?.company &&
                                    <ActivateSegmentForm
                                        broadcastFormCloseEvent={formCloseEvent}
                                        broadcastReloadEvent={reloadEventFromChild}
                                        data={{companyData: selectedCompany, creativeTags: selectedCreativeTags, activateView: activateView, ftpConnections: connections}}/>
                                }
                            </LoadOverlay>
                        </div>
                            <div className="col-lg-3 spacer">
                                <div className="card">
                                </div>
                            </div>
                    </div>
                </LoadOverlay>
            </div>
        </div>
    );
}

export default ActivateSegment;
