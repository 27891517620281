import {
    ADMIN_SETUP_DOWNLOADED_CSV_FILE_NAME,
    ASCENDING_ORDER,
    AUTH_COOKIE,
    DASHBOARD_TREE,
    LOGIN_AS,
    NO_DATA_TO_SHOW,
    SIGN_OUT_API,
    SUCCESS_STATUS,
    USER,
    YYYY_MM_DD_H_MM_SS_A_DATEFORMAT,
} from "../api/constants";
import {toast} from "react-toastify";
import axios from "axios";
import moment from "moment";
import {ProgressBar} from "react-bootstrap";
import {UDX} from "./UDX";
import React from "react";
// return the userId data from the session storage
export const semcastAxios = (() => {
    const semcastAxiosInstance = axios.create();
    semcastAxiosInstance.interceptors.request.use((config) => {
        if (
            window.location.hostname === "localhost" ||
            window.location.hostname === "127.0.0.1"
        ) {
            config.headers["Cookie"] = getAuthCookieFromStorage();
            config.headers["Content-Type"] = "text/plain";
        }
        return config;
    });
    //FD:10787
    semcastAxiosInstance.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        if (error.response.status  === 401) {
            if (error.response.request.responseURL === DASHBOARD_TREE && extractAuthCookie() === "") {
                signOut();
            } else {
                return Promise.reject(error);
            }
        } else {
            return Promise.reject(error);
        }
    });
    return semcastAxiosInstance;
})();


export const getUser = () => {
    const userId = sessionStorage.getItem("userId");
    if (userId) return userId;
    else return null;
};

// return the isLogged from the session storage
export const isUserLoggedIn = () => {
    return sessionStorage.getItem("isLogged") ? true : false;
};

// remove the isLogged and userId from the session storage
export const removeDataFromStorage = () => {
    sessionStorage.clear();
    //sessionStorage.removeItem("isLogged");
    //sessionStorage.removeItem("userId");
};

// set the isLogged and userId from the session storage
export const setDataToStorage = (isLogged, userId) => {
    sessionStorage.setItem("isLogged", isLogged);
    sessionStorage.setItem("userId", userId);
};

export const setAuthCookieToStorage = (authCookie) => {
    sessionStorage.setItem(AUTH_COOKIE, authCookie);
};

export const getAuthCookieFromStorage = () => {
    return sessionStorage.getItem(AUTH_COOKIE);
};

export const setUserDataToStorage = (user) => {
    sessionStorage.setItem(USER, JSON.stringify(user));
};

export const getUserDataFromStorage = () => {
    return sessionStorage.getItem(USER);
};

// extract "auth" cookie from response
export const extractAuthCookie = () => {
    try {
        let name = AUTH_COOKIE + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
    } catch (e) {
    }
    return "";
};

export const getAuthCookieHeader = () => {
    return window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
        ? {
            Cookie: getAuthCookieFromStorage(),
            "Content-Type": "text/plain",
        }
        : {};
};

export const showSuccessToastMessage = (message, time) => {
    if (time == null) time = 5;

    toast.success(message, {
        position: "top-right",
        autoClose: time * 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};
export const showErrorToastMessage = (message, time) => {
    // if(toast.isActive("LogInErrorMsg")) {
    //     await toast.dismiss("LogInErrorMsg");
    // };

    if (time == null) time = 5;

    toast.error(message, {
        toastId: "LogInErrorMsg",
        position: "top-right",
        autoClose: time * 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};
export const showToastMessage = (message, type = "success") => {
    if (type && type.toLowerCase() === "success") {
        showSuccessToastMessage(message);
    } else if (type && type.toLowerCase() === "error") {
        showErrorToastMessage(message);
    }
};

export const hideNShow = (e, show = false) => {
    e.target.style.display = show ? "none" : "block";
};
let location_properties_list = [];
export const handleCheckboxChange = async (
    e,
    formikField,
    formik,
    activateFormBase
) => {
    const {checked, name} = e.target;
    if (checked === true && !formik.values[formikField].includes(name)) {
        formik.setFieldValue(formikField, [...formik.values[formikField], name]);
    } else if (checked === false && formik.values[formikField].includes(name)) {
        formik.setFieldValue(
            formikField,
            formik.values[formikField].filter((v) => v !== name)
        );
    }
    //Activation Measurement tile rules
    //Using a list to update fieldvalue because formik is updated async
   if(activateFormBase.company === "semcastingmeasurement"){

       if (checked) {
           location_properties_list.push(name)
       if(name === 'consumer_pii' && location_properties_list.includes('consumer_basics')){
                   location_properties_list = location_properties_list.filter(function(item) {
                       return item !== 'consumer_basics'
                   })
           formik.setFieldValue(formikField, location_properties_list);
           }else if(name === 'consumer_basics' && location_properties_list.includes('consumer_pii')){
           location_properties_list = location_properties_list.filter(function(item) {
               return item !== 'consumer_pii' && item !== 'consumer_demographics'
           })
           formik.setFieldValue(formikField, location_properties_list);
       } else if(name === 'consumer_demographics' && location_properties_list.includes('consumer_basics')){
           location_properties_list = location_properties_list.filter(function(item) {
               return item !== 'consumer_basics'
           })
           formik.setFieldValue(formikField, location_properties_list);
       } else if(name === 'consumer_basics' && location_properties_list.includes('consumer_demographics')) {
           location_properties_list = location_properties_list.filter(function(item) {
               return item !== 'consumer_demographics'
           })
           formik.setFieldValue(formikField, location_properties_list);
       }
       } else if (checked === false) {
           location_properties_list = location_properties_list.filter(function(item) {
               return item !== name
           })
           formik.setFieldValue(
               formikField,
               formik.values[formikField].filter((v) => v !== name)
           );
       }

   }
};
let trending_list = [];
export const handleTrendingCheckboxChange = async (
    e,
    formikField,
    formik,
    segmentData
) => {
    const {checked, name} = e.target;

    // Number formatter.
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    if (checked) {
        await formik.setFieldValue(formikField, [...formik.values[formikField], name]);
        trending_list.push(name);
    } else {
        await formik.setFieldValue(
            formikField,
            formik.values[formikField].filter((v) => v !== name)
        );

        trending_list = trending_list.filter(function(item) {
            return item !== name
        })
    }
    let t = Math.max(segmentData.total_businesses, segmentData.total_households)
    let c = trending_list.length;
    let n = formatter.format(Math.max((t * c) * .0005, c * 10.00));
    formik.setFieldValue('calculated_cost', n,true);
    document.getElementById('calculated_cost').value = n;

};
export const handleDropDownChange = (
    e,
    formikField,
    formik
) => {
    if (e.target.value) {
        formik.setFieldValue(formikField, e.target.value.val);
    }
};

export const handleDatePickerStartDateChange = (
    e,
    formikField,
    formik
) => {
    if (e.target.value) {
        //formik.setFieldValue("campaign_range_start", e.target.value.toISOString());
        formik.setFieldValue("campaign_range_start", e.target.value.toISOString().split('T')[0]);
    }
};
export const handleDatePickerEndDateChange = (
    e,
    formikField,
    formik
) => {
    if (e.target.value) {
        //formik.setFieldValue("campaign_range_end", e.target.value.toISOString());
        formik.setFieldValue("campaign_range_end", e.target.value.toISOString().split('T')[0]);
    }
};
export const handleDropDownChangeSemcastingXandr = (
    e,
    formikField,
    formik
) => {
    if (e.target.value) {
        let val = e.target.value.id.toString();
        formik.setFieldValue(formikField, val);
    }
};
export const handleDropDownChangeCreativeTags = (
    e,
    formikField,
    formik
) => {
    if (e.target.value) {
        let Id = e.target.value.creativeTagId.toString();
        //Encode tag to pass XSS issue
        let Tag = encodeURIComponent(e.target.value.creativeTag.toString());
        // let Tag = e.target.value.creativeTag.toString();
        formik.setFieldValue("creativeTagId", Id);
        formik.setFieldValue("creativeTag", Tag);
        formik.setFieldValue("creativeTagIdDropDown", Id);

    }
};

// export const handlePinterestAccessChange = (
//   e,
//   formikField,
//   formik
// ) => {
// console.log("handle access", e.target.value);
//   window.addEventListener("message", (event)=>{
//     console.log(event.data)
//     formik.setFieldValue(formikField,event.data);
// Normally you would check event.origin
// To verify the targetOrigin matches
// this window's domain
// let token=document.getElementById("accessToken")
// event.data contains the message sent
// token.value=event.data;

// });
//   formik.setFieldTouched(formikField, true);
// formik.setFieldValue(formikField, document.getElementById("accessToken").value);
// };

export const handlePinterestAccessClick = (
    e,
    formikField,
    formik
) => {

    function OpenWindow() {
        const height = 850;
        const width = 350;
        const top = window.innerHeight - height;
        const left = window.innerHeight - width;
        window.open(
            "https://www.pinterest.com/oauth/?client_id=1482772&redirect_uri=https://ads-demo.semcasting.internal/segmentation-dashboard&response_type=code&scope=ads:read,ads:write&state=",
            'myWindow',
            'location=yes,height=' + height + ',width=' + width + ',top=' + top + ',left=' + left + ',scrollbars=yes,status=yes'
        );
    }

    OpenWindow();

    window.addEventListener("message", (event) => {
        formik.setFieldValue(formikField, event.data);
    });
};
//Handle Google Ads Access Code
export const handleGrantAccessClick = ( e, formikField, formik, activateFormBase) => {
    // console.log(activateFormBase.segment.id)
    // This is needed for the redirectUri and will vary by active environment.
    let origin = window.location.origin;
    if (activateFormBase.company === 'googleads') {
        let clientId = '483500096558-71jkff1220j3jckc27skju3c7fmpiqvl.apps.googleusercontent.com';
        // NOTE: Google does not allow localhost
        let googleAdsOrigin = (origin === 'http://localhost:8080' ? ('http://127.0.0.1:8080') : (origin)); // BKR This is a necessary hack.
        let redirectUri = encodeURIComponent(googleAdsOrigin + '/google/callback');
        let scope = encodeURIComponent('https://www.googleapis.com/auth/adwords');
        let state = activateFormBase.segment.id;
        let authUrl = 'https://accounts.google.com/o/oauth2/v2/auth?client_id=' + clientId +
            '&redirect_uri=' + redirectUri +
            '&response_type=code' +
            '&scope=' + scope +
            '&include_granted_scopes=true' +
            '&access_type=offline' +
            '&prompt=consent' +
            '&state=' + state;
        const windowFeatures = "left=100,top=100,width=500,height=800";
        window.open(authUrl, "GoogleAdsAuthenticate", windowFeatures);
    }
    // PINTEREST

 else if (activateFormBase.company === 'pinterest') {
        let clientId = '1482772';
    // NOTE: Pinterest does not allow 127.0.0.1
        let redirectUri = encodeURIComponent(origin + '/pinterest/callback');
        let scope = encodeURIComponent('ads:read,ads:write');
        let state = activateFormBase.segment.id;
        let authUrl = 'https://www.pinterest.com/oauth/?client_id=' + clientId +
        '&redirect_uri=' + redirectUri +
        '&response_type=code' +
        '&scope=' + scope +
        '&state=' + state;
    const windowFeatures = "left=100,top=100,width=500,height=800";
    window.open(authUrl, "PinterestAuthenticate", windowFeatures);

    // TIKTOK

} else if (activateFormBase.company === 'tiktok') {
        let appId = '7260405326367686657';
        let state = activateFormBase.segment.id;
        let redirectUri = encodeURIComponent(origin + '/tiktok/callback');
        let rid = 'jfgvkek0avn';
        let authUrl = 'https://ads.tiktok.com/marketing_api/auth' +
            '?app_id=' + appId +
            '&state=' + state +
            '&redirect_uri=' + redirectUri +
            '&rid=' + rid;
        const windowFeatures = "left=100,top=100,width=500,height=800";
        const popup = window.open(authUrl, "TikTikAuthenticate", windowFeatures);
    }
}

export const putCheck = (e, value, formikField, formik) => {
    //return formik?.values?.[formikField].includes(value);
    if (e.target.checked) {
        formik.setFieldValue(formikField, value);
    } else {
        formik.setFieldValue(formikField, !value);
    }

    return formik?.values?.[formikField];
};
export const putCheckWithObject = (object, objectKey, formikField, formik) => {
    return formik?.values?.[formikField].find(
        (i) => i[objectKey] === object[objectKey]
    );
};

export const handleCheckboxChangeWithObject = (
    e,
    object,
    objectKey,
    formikField,
    formik,
    getCheckboxValue
) => {
    const {checked} = e.target;
    if (checked) {
        formik.setFieldValue(formikField, [...formik.values[formikField], object]);
        if (formik.values[formikField].length === 0) {
            getCheckboxValue(object.segment_name, object.id);
        }
    } else {
        formik.setFieldValue(
            formikField,
            formik.values[formikField].filter(
                (v) => v[objectKey] !== object[objectKey]
            )
        );
        if (formik.values[formikField].length === 2) {
            if (object.segment_name !== formik.values[formikField][0].segment_name) {
                getCheckboxValue(
                    formik.values[formikField][0].segment_name,
                    formik.values[formikField][0].id
                );
            } else {
                getCheckboxValue(
                    formik.values[formikField][1].segment_name,
                    formik.values[formikField][1].id
                );
            }
        }
    }
};

export const signOut = async () => {
    try {
        await semcastAxios.get(SIGN_OUT_API, {withCredentials: true});
        removeDataFromStorage();

        // reset login attempts cookie
        document.cookie = "attempts=0";

        window.location.href = "/"
        // history.push("/");
    } catch (e) {
    }
}

export const signInAs = async (id) => {
    const res = await semcastAxios.get(LOGIN_AS + id, {
        withCredentials: true,
    });
    if (res.data.status === SUCCESS_STATUS)
        window.location.href = "/segmentation-dashboard";
};

export const sortItemsByField = (
    items,
    sortedField,
    sortOrder = ASCENDING_ORDER
) => {
    if (sortedField !== null) {
        items.sort((a, b) => {
            if (
                (a[sortedField] + "").toLowerCase() <
                (b[sortedField] + "").toLowerCase()
            ) {
                return sortOrder === ASCENDING_ORDER ? -1 : 1;
            }
            if (
                (a[sortedField] + "").toLowerCase() >
                (b[sortedField] + "").toLowerCase()
            ) {
                return sortOrder === ASCENDING_ORDER ? 1 : -1;
            }
            return 0;
        });
    }
};

export const paginationCustomTotal = (from, to, size) => (
    <span className="ml-3 react-bootstrap-table-pagination-total">
    Showing {from} to {to} of {size}
  </span>
);
export const paginationOptions = (rows) => {
    return {
        paginationSize: 4,
        //pageStartIndex: 0,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        /*  firstPageText: 'First',
                prePageText: 'Back',
                nextPageText: 'Next',
                lastPageText: 'Last',
                nextPageTitle: 'First page',
                prePageTitle: 'Pre page',
                firstPageTitle: 'Next page',
                lastPageTitle: 'Last page',*/
        showTotal: true,
        paginationTotalRenderer: paginationCustomTotal,
        //disablePageTitle: true,
        sizePerPageList: [
            {
                text: "10",
                value: 10,
            },
            {
                text: "20",
                value: 20,
            },
            {
                text: "50",
                value: 50,
            },
            {
                text: "100",
                value: 100,
            },
            {
                text: "All",
                value: rows?.length,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };
};

export const noDataIndication = () => {
    return NO_DATA_TO_SHOW;
};
export const exportCSVOptions = () => {
    return {
        fileName: ADMIN_SETUP_DOWNLOADED_CSV_FILE_NAME,
    };
};
export const boostrap2TableSearchOptions = () => {
    return {};
};
export const ExportCSV = (props) => {
    const handleClick = () => {
        props.onExport();
    };
    return (
        // <div className="d-flex justify-content-end align-items-center float-right">
            <a
                href="javascript:void(0)"
                className="btn btn-primary float-right"
                title="CSV"
                onClick={handleClick}
            >
                <i className="fa fa-arrow-down"></i>CSV
            </a>
        // </div>
    );
};
export const defaultSortedOnTotal = [
    {
        dataField: "total",
        order: "desc",
    },
];

// =========== TABLE COLUMN  FORMATTERS START==============
export const emailFormatter = (cell, row) => {
    try {
        if (cell) {
            return (
                <a href={`mailto:${cell}`} target="_blank">
                    {cell}
                </a>
            );
        }
    } catch (e) {
        console.log(e);
        return <></>;
    }
};

export const loginColumnFormatter = (cell, row) => {
    try {
        return (
            <a
                href="javascript:void(0)"
                className="btn btn-primary shadow btn-xs sharp mr-1"
                onClick={() => signInAs(cell)}
            >
                <i className="fa fa-sign-in"/>
            </a>
        );
    } catch (e) {
        console.log(e);
        return <></>;
    }
};
export const errorColumnFormatter = (cell, row) => {
    try {
        return <span className="text-danger">{cell}</span>;
    } catch (e) {
        console.log(e);
        return <></>;
    }
};

export const progressbarFormatter = (cell, row) => {
    try {
        if (cell === 0 || cell) {
            return (
                <ProgressBar
                    animated
                    variant="info" /*label={`${row?.overall_percent}%`}*/
                    now={cell}
                />
            );
        }
    } catch (e) {
        console.log(e);
        return <></>;
    }
};

export const dateFormatter = (
    cell,
    row,
    FORMAT = YYYY_MM_DD_H_MM_SS_A_DATEFORMAT
) => {
    try {
        if (cell) {
            return <>{moment(cell).format("YYYY-MM-DD h:mm:ss A")}</>;
        }
    } catch (e) {
        console.log(e);
        return <></>;
    }
};

// =========== TABLE COLUMN  FORMATTERS  END ==============

// =========== TABLE COLUMN CUSTOM FILTER VALUES  START ==============

/*export const emailCellFilterValue =(cell, row) => {
    try {
        return cell?.split("@")[0]
    } catch (e) {
        return ""
    }
}*/

// =========== TABLE COLUMN CUSTOM FILTER VALUES END ==============

export const priceFormat = (price, currency = UDX.currencyCodes.USD) => {
    try {
        return (
            currency +
            price.toLocaleString(navigator.language, {minimumFractionDigits: 2})
        );
    } catch (e) {
        return currency + "0.00";
    }
};
